import { PageContent, completeCallback, JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, render, patch, linkClick } from '@appzuka/rxnano';
import { first, map} from 'rxjs/operators';
import { metalBlock } from 'site/components/metalBlock/metalBlock';

import './sectionHeader.scss';

const sectionHeader = (title) => {
    metalBlock(<h2>{title}</h2>, {class: 'section-header'})
}

export {
    sectionHeader
}
