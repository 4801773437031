import { PageContent, JSXFactory, render, sitePix, exportedSiteData, } from '@appzuka/rxnano';
import { PlaceholderMultiBlock } from 'site/placeholders/placeholders';
import { aboutSubMenu } from './aboutPages';
const NewsData = [{
  title: 'Stage V Engines',
  link: 'stagev',
  image: 'posts/stagev',
  snippet: 'The UK GOV and EU have changed the engine emission legislation to assist in the reduction of emissions.',
  body: <div class='news-body'>
    <h2>What You Need to Know</h2>
    <p>The UK GOV and EU have changed the engine emission legislation to assist in the reduction of emissions, this is known as Stage V and came into effect on 1st January 2019, Overland thought it would be a good idea to clarify some facts and how it will affect our industry.</p>
    <p>Stage V legislation represents a big change to the engines used in non-road mobile machinery, such as wood chippers,  stump grinders, mowers, and skid steer machinery.</p>
    <p>Some of our customers may not be fully aware of the changes, so we thought, lets cut the wheat from the chaff and focus on a few points that are important to you and Overland.</p>
    <ul>
      <li><p>On the 1st January 2019, The Stage V legislation took effect and is aimed at non-road mobile machinery (NRMM’s) and their emissions.</p></li>
      <li><p>Any existing diesel engines, including machines built and ready for sale are not affected and can continue to be used.</p></li>
      <li><p>Manufacture of pre-Stage V diesel engines ceased at the end of December 2018.</p></li>
      <li><p>Pre-built stocks of non-Stage V Diesel Engines can continue to be used by manufacturers until June 2020.</p></li>
      <li><p>As of July 2020, all new woodchippers and NRMM’s, must be fitted with Stage V diesel engines.</p></li>
      <li><p>The July 2020 deadline has now been postponed, due to the Covid-19 pandemic.</p></li>
    </ul>
  </div>
},
{ // FIXME: Check final url (www?)
  title: 'A Customer\'s Purchasing Experience',
  link: 'customerstory',
  image: 'posts/customerstory',
  snippet: 'Toyota Hilux Arbtruck Tipper Recently a customer told us the story of his new Arbtruck purchase.',
  body: <div class='news-body'>
    <h2>Recently a customer told us the story of his new Arbtruck purchase.</h2>
    <p>The story began when our customer was working with an arborist colleague who had recently purchased a Toyota Hilux Arbtruck Tipper from Overland.</p>
    <p>After several days in the woods preparing for timber extraction, he began to appreciate the amount of thought that had been placed in the design and development, also the high-quality of craftsmanship in the build.</p>
    <p>Our customer visited our new revamped web site <a href='/'>https://overland-es.com</a> where he found a wealth of options and choices available to him.</p>
    <p>After contacting sales, the customer was invited to visit our workshops to see an Arbtruck under construction and meet the team.</p>
    <p>While at the Overland head office, our customer was taken step by step through the order process, from bespoke personalisation to construction, build and testing.</p>
    <p>So pleased with the overall professionalism of the company and staff, not forgetting the Arbtruck product, our customer placed his order there and then.</p>
    <p>Overland pride themselves on keeping the customer informed of each step through the build and include pictures to illustrate progress.</p>
    <p>Several weeks later and with a broad smile, the customer took ownership of his very bespoke, high quality, Overland Arbtruck Tipper.</p>
  </div>
},
{
  title: 'Toyota HiLux ArbTruck',
  link: 'arbtruck',
  image: 'posts/arbtruck',
  snippet: 'Another Overland wide bodied Hilux Arb Truck off to a happy customer.',
  body: <div class='news-body'>
    <h2>Another Satisfied Customer</h2>
    <p>Another Overland wide bodied Hilux Arb Truck off to a happy customer.</p>
    <p>This particular customer wanted a Bespoke tool box with pull out drawers capable of carrying a variety of tools. Fitted with a winch bumper and a remote control Warn winch.</p>
    <p>At Overland we offer a range of Arb Truck and are happy to build Bespoke trucks to suit specific needs.</p>
  </div>
}];

const NewsIndex = (skip?) => {
  PlaceholderMultiBlock(skip ? 'Other Overland News' : 'Overland News', undefined, NewsData
    .filter(n => n.link !== skip)
    .map(n => {
      return({title: n.title, image: n.image, link: `/about/news/${n.link}`});
    }));
}

const newsPages : PageContent = {
    content: [
        {
          pageLocation: 'about/news',
          image: {imageName: 'banners/aerial', title: 'About Us'},
          pageTitle: 'News',
          content: () => {
            render(<div class='container'>
              { NewsIndex }

            </div>)
          }
        },
        {
          pageLocation: 'about/news/:story',
          image: {imageName: 'banners/aerial', title: 'About Us'},
          pageTitle: 'News Story',
          content: (_, { story }) => {
            const thisItem = NewsData.find(n => n.link === story);
            if (!thisItem) {
              // TODO: Expand error message
              render(<div class="container">
                <h2>Story not found</h2>
                { NewsIndex }
                </div>);
                return;
            }
            render(<div class="news-story-body">
                { () => sitePix(exportedSiteData.assetBase, thisItem.image, { mediaSizes: '1100px'}) }
                {thisItem.body}
                { () => NewsIndex(story)}
              </div>)
          },
          name: 'news-story'
        },
    ],
    subMenu: aboutSubMenu

}

export {
    newsPages
};

