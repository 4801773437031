import { PageContent, completeCallback, JSXFactory, sitePix, exportedSiteData, render } from '@appzuka/rxnano';

import './placeholders.scss';

interface PictureBlock {
  title: string,
  image: string,
  description?: string
  link?: string
}

const PlaceholderBlockOrCarousel = (carousel:boolean, title:string, link?:string, items?:Array<string | PictureBlock | Function >) => {

  const content = <div class='placeholder-content placeholder-carousel-wrapper'>
    { carousel ? <div class='placeholder-carousel-prev'>&lt;</div> : undefined}
    {items && (() => {
      items.map((item, i) => {
        if (typeof item === "string") {
          render(<div class={`carousel-block${i===0?' first-block':''}`}>{item}</div>)
        } else if (typeof item === "function") {
          item();
        } else {
          // FIXME: Look into how the mediasizes affects content size
          if (item.link) {
            render(<a href={item.link} class={`carousel-block carousel-image-block ${i===0?' first-block':''}`}>
            <h2>{item.title}</h2>
            {() => sitePix(exportedSiteData.assetBase, item.image, { mediaSizes: '400px'})} 
          </a>)
          } else {
            render(<div class={`carousel-block carousel-image-block ${i===0?' first-block':''}`}>
            <h2>{item.title}</h2>
            {() => sitePix(exportedSiteData.assetBase, item.image, { mediaSizes: '400px'})} 
          </div>)
          }

        }
      })}
    )}
    { carousel ? <div class='placeholder-carousel-next'>&gt;</div> : undefined}
  </div>

  PlaceholderBlock(title, link, content)
}

const PlaceholderCarousel = (title:string, link?:string, items?:Array<string | PictureBlock | Function>) => {
  PlaceholderBlockOrCarousel(true, title, link, items);
}

const PlaceholderMultiBlock = (title:string, link?:string, items?:Array<string | PictureBlock | Function>) => {
  PlaceholderBlockOrCarousel(false, title, link, items);
}

const PlaceholderBlock = (title?:string, link?:string, content?:any) => {
  render(<div class='placeholder block'>
    {title && <h1>{title}</h1>}
    {content || <div class='placeholder-content'/>}
    {link && <a class='more-link' href={link}>Click here for more ...</a>}
  </div>);
}

const PlaceholderPageBanner = (title: string) => {
  render(<div class='placeholder-margin-container'>
    { () => PlaceholderBlock(title)}
</div>);

}

export {
  PlaceholderCarousel,
  PlaceholderBlock,
  PlaceholderMultiBlock,
  PlaceholderPageBanner 
};

