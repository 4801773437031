
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('h2');text(`SLAVERY & TRAFFICKING POLICY`);elementClose('h2');
elementOpen('h3');text(`OVERLAND ENVIRONMENTAL SERVICES LTD ANTI-SLAVERY & HUMAN TRAFFICKING POLICY`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('The Modern Slavery Act (2015) – Annex A outlines the following, as examples of modern slavery:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Slavery and Servitude`);elementClose('li');
elementOpen('li');text(`Forced or Compulsory labour`);elementClose('li');
elementOpen('li');text(`Human Trafficking`);elementClose('li');
elementOpen('li');text(`Child Labour`);elementClose('li');
elementClose('ul');
elementOpen('p');
text('Overland Environmental Services is a provider of Arboriculture services in both the utility and amenity sectors. The organisation is a limited company and applies the structure within the document ORG01 (latest version 15/04/2019).')
elementClose('p');
elementOpen('p');
text('Overland Environmental Services adopts a zero-tolerance policy on Modern Slavery and is dedicated to abolishing any Slavery and Human Trafficking actions on all premises and the premises of our suppliers.  The act is observed in all aspects of the Overland Environmental Services supply chain questionnaire (QF14) which challenges suppliers to demonstrate their commitment to the Modern Slavery Act. We require our suppliers to operate as we would and any potential supplier that is unable to detail their own policies and procedures will be excluded from the procurement process. We reserve the right at any point to challenge suppliers to demonstrate the following:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`the sector(s) the business operates in and whether any of its work is seasonal`);elementClose('li');
elementOpen('li');text(`the organisational structure and group relationships`);elementClose('li');
elementOpen('li');text(`the countries it sources its goods or services from including high-risk countries where modern forms of slavery are prevalent.`);elementClose('li');
elementOpen('li');text(`the make-up and complexity of the supply chains`);elementClose('li');
elementOpen('li');text(`the businesses operating model`);elementClose('li');
elementOpen('li');text(`relationships with suppliers and others, including trade unions and other bodies representing workers`);elementClose('li');
elementClose('ul');
elementOpen('p');
text('It is the policy of the company to ensure that:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Employees are fairly remunerated by the company at or above the minimum wage threshold`);elementClose('li');
elementOpen('li');text(`New suppliers complete the questionnaire and satisfy our criteria in accordance with the Act`);elementClose('li');
elementOpen('li');text(`On-going suppliers must provide copies of any changes to their policies and procedures in relation to Modern Slavery`);elementClose('li');
elementOpen('li');text(`The mandatory challenge, where ‘red flags’ are raised, e.g. identification of:`);elementClose('li');
elementOpen('li');text(`Poor working conditions`);elementClose('li');
elementOpen('li');text(`Poor or no health and safety support, an/or record`);elementClose('li');
elementOpen('li');text(`Working hours`);elementClose('li');
elementClose('ul');
elementClose('div');
elementOpen('h3');text(`Risks`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('It is difficult to identify modern slavery through the entire supply chain process. Overland Environmental Services and its activities, with exception to direct remuneration or in the payment of sub-contract labour, is a provider of services. Therefore, our responsibilities to Modern Slavery are clear. The same cannot be said of our suppliers, as there is a higher risk associated with the entire supply chain and there is a reliance upon the integrity of each part of the supply chain through to the source.')
elementClose('p');
elementOpen('p');
text('We mitigate this risk by challenging our suppliers to challenge their own suppliers in turn, so to demonstrate compliance to the Act, as far down the supply chain as is reasonably practicable.')
elementClose('p');
elementOpen('p');
text('It is our policy to maintain a log of suppliers that refused/were unable to comply with our requirements in relation to the Act. This approved supplier list governs the procurement process to ensure that the company sustainably sources from suppliers, thus ensuring our continued compliance.')
elementClose('p');
elementOpen('p');
text('Identifying Modern Slavery ‘red flags’ is part of our annual toolbox talk process, where the company educates its employees (including sub-contract) on key indicators of Modern Slavery and the reporting process.')
elementClose('p');
elementClose('div');
elementOpen('h3');text(`Statement`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('As detailed within the act, it is our policy to maintain (reviewing where necessary) a Modern Slavery statement which can be made available on request and will always be displayed for review through any of our online platforms.')
elementClose('p');
elementClose('div');
elementOpen('h3');text(`Responding to an act of Modern Slavery:`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Overland Environmental Services maintains a latest copy of the Government guidance documents on Modern Slavery. Annexe F within this document identifies the government bodies that should be notified if Modern Slavery is determined. The Modern Slavery helpline (0800 0121 700) has been communicated to the management team and is included within our list of key contact information.')
elementClose('p');
elementOpen('p');
text('In addition to reporting acts of Modern Slavery in accordance with the guidelines, if the person, representative or organisation was an approved or prospective supplier, Overland Environmental Services will cease trading with them, pending investigation or until such time that our policy for zero-tolerance can be demonstrably observed.')
elementClose('p');
elementOpen('p');
text('This Policy shall be reviewed in the light of newly amended, applicable laws and regulations, and when it is deemed necessary to amend controls and interventions.')
elementClose('p');
elementClose('div');
  elementClose('div');
  return el;
};