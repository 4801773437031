import { JSXFactory } from '@appzuka/rxnano';
import { DisplayListItem } from 'site/components/displayList/displayList';




export const hireSummaryData:DisplayListItem[] = [
{
    image: 'vehicles/toyota',
    link: '/hire/arbtrucks',
    imageTitle: 'Vehicles',
    content: <div class='dl-news-content'>
        <h2>Created to Perform Under All Conditions</h2>
    <p>The Arbtruck tipper conversion from Overland has been created over many years by our highly experienced Arborists and has proven itself to be a reliable, steadfast, easy to use, essential work tool.</p>
    </div>
},
{
    image: 'woodchippers/motorised/175/1',
    link: '/hire/woodchippers',
    imageTitle: 'Woodchippers',
    content: <div class='dl-news-content'>
        <p>Overland is the sole approved UK distributor of Schliesing wood chippers. There are three main types of wood chipper:</p>
        <ul>
            <li>PTO - Power Take Off, usually driven by a tractor.</li>
            <li>Special - These are track mounted for rough terrain.</li>
            <li>Motorised - Powered by their own diesel or petrol engine.</li>
        </ul>
        <p>Overland offer Woodchippers to Buy or Hire. We also offer maintenance services and parts for woodchippers.</p>
    </div>
},
{
    image: 'mewps/tb260',
    link: '/hire/aerialplatforms',
    imageTitle: 'Aerial Platforms',
    content: <div class='dl-news-content'>
    <p>Overland have a selection of Aerial Platforms for hire.</p>
    </div>
},

];
