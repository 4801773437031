// Headmenu is shown at the top of each page.  It is the first div in the container
// div, both of which are inserted in the original html file

import {
  patch,
  elementOpen,
  elementClose,
  elementVoid,
  text
} from 'incremental-dom';

import './headmenu.scss';
import './menucontent.scss';
// import './topmenu.scss';

import aerialIcon from './icons/white/aerial.png';
import chipperIcon from './icons/white/chipper.png';
import diggerIcon from './icons/white/digger.png';
import maintenanceIcon from './icons/white/maintenance.png';
import treeIcon from './icons/white/tree.png';
import truckIcon from './icons/white/truck.png';

import { linkClick, rxnanoIcons, exportedSiteData, MenuType, isNil, PageListItem, render } from '@appzuka/rxnano';
import { timer } from 'rxjs';
// import { takeWhile, map, filter, first } from 'rxjs/operators';

import { renderHomeBanner } from './renderhomebanner';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';
import { hireAerialPages } from 'site/pages/hire/hire';

const heroContent = require('./homeherocontent.json');

interface HMStore {
  shorten: number,
  actual: number
};

const homeIcons = [
  truckIcon,
  treeIcon,
  chipperIcon,
  diggerIcon,
  aerialIcon,
  maintenanceIcon
];

// TODO: memoize menu length

let headMenuStore = {
  shorten: 0,
  actual: 0
} as HMStore;

const headMenuShorten = (by: number): void => {
  headMenuStore.shorten = by < 0
    ? 0
    : headMenuStore.shorten > 10
      ? headMenuStore.shorten
      : headMenuStore.shorten + by;
}

const HeadMenu = (pageChangeObservable, headMenuContent, headerLogo, dropmenu) => {
  const hm = Object.create({});



  // If the prerendered headmenu is already there don't add it again.
  if (!document.getElementById('headmenuwrapper')) {
    const bwel = document.createElement('div');
    bwel.setAttribute('id', 'headmenuwrapper');
    const pw = document.getElementById('container');
    pw!.insertBefore(bwel, pw!.firstChild);
    // FIXME: What if there is no firstChild in container?
  };

  pageChangeObservable.subscribe({
    next: (ps: PageListItem) => {
      const menuContainer = document.getElementById('headmenuwrapper');
      if (typeof(ps.pageLocation) === 'string' && (ps.pageLocation.length == 0)) { // Could also be a RegExp
        menuContainer!.setAttribute('class', 'homehero');
      } else {
        menuContainer!.setAttribute('class', 'normalbanner');
      }

      if (menuContainer !== null) {
        patch(menuContainer as Element, () => headMenu(headMenuContent, headerLogo, dropmenu, ps.subMenu, ps.subMenuClass));
        dropmenu.reset();
      }
    }
  });

  return hm;
}

const headMenuFormattedContent = (headMenuContent, currentLocation, shorten, menuType: MenuType = MenuType.Head) => {
  elementOpen('div', null, null, 'id', 'menucontent');

  let displayList = headMenuContent
    .filter((m) => ((shorten === 0) || (currentLocation !== m.link))) // shorten:1 cuts just the current link
    .filter((m) => (m.priority > shorten))
    .map(m => ({ menuText: m.menuText, link: m.link }));

  if (displayList.length < 2) {
    // NICE: Could also make this open the menu

    elementOpen('p');
    if (exportedSiteData.pageLabels[currentLocation] !== undefined) {
      text(exportedSiteData.pageLabels[currentLocation]);
    } else {
      text(currentLocation.split('/').slice(-1)[0]);
    }

    elementClose('p');
  } else {
    elementOpen('ul', null, ['id', menuType === MenuType.Top ? 'mclist-top' : 'mclist-head']);
    displayList.map(({ menuText, link }, i) => {

      elementOpen('li');
      elementOpen('a', `mc-${i}`, null, 'href', link,
        'class', currentLocation === link ? 'active-link' : 'other-link',
        'onclick', (event) => {
          linkClick(event, link);
        });
      text(menuText);
      elementClose('a');
      elementClose('li');
    });
    elementClose('ul');
  }
  elementClose('div');
};

const afterRepaint = (f: any): void => {
  window.requestAnimationFrame(() => window.requestAnimationFrame(f));
}

let windowWidth: number = window.innerWidth;

// NICE: Move submenu into own component
const subMenuContent = (subMenu, subMenuClass?) => {
  if (subMenu.length === 0) return;
  elementOpen('div', `sub-menu-${subMenuClass}`, ['id', `sub-head-menu`, 'class', [`submenu`, subMenuClass].filter(c => c !== undefined).join(' ')]);
  elementOpen('ul', `sub-menu-ul`, null, 'id', `sub-head-menu-ul`);
  subMenu!.map(({ label, link, shortLabel }) => {

    if (link !== undefined) {
      elementOpen('a', null, null, 'href', link,
        'class', window.location.pathname === link ? 'active-link' : 'other-link',
        'onclick', (event) => {
          linkClick(event, link);
        });
      elementOpen('li', `sub-menu-li`, null);
      text(window.innerWidth > 450 ? label : shortLabel || label);
      elementClose('li');
      elementClose('a');
    } else {
      elementOpen('li', `sub-menu-li`, null);
      text(label);
      elementClose('li');
    }
  });
  elementClose('ul');
  elementClose('div');
}


const homeHeroContent =
  <div class="homeherocontent">
    <h2 class="homeherocontenttitle">{heroContent.title}</h2>
    <h2 class="homeherocontentmission">{heroContent.mission}</h2>
    {() => homeHeroContentLast('upper')}
  </div>

const homeHeroContentLast = (classid?: string) => {
  render(
    <div class={["homeherocontentaboutwrapper", classid].filter(c => c !== undefined).join(' ')}>
      {() => {
        heroContent.sectors.map((s, i) => {
          render(<a href={s.link} class="homeherocontentsector">
            <div class="sector-row1">
              <div class="sector-icon">
                <img src={homeIcons[i]} />
              </div>
              <div class="sector-title">
                <h4>{s.name}</h4>
                <ul class="sector-tags">
                  { () => {
                    s.tags.map(t => {
                      render(<li>{t}</li>)
                    })
                  }}
                </ul>
              </div>
            </div>
            <p class="sector-row2">
              {s.description}
            </p>
          </a>
          )
        })
      }}
    </div>
  );
}

// TODO: The menucontent changes when the page changes
const headMenu = (headMenuContent, headerLogo, dropmenu, subMenu, subMenuClass?) => {

  elementOpen('div', null, null, 'id', 'largebanner');
  elementOpen('div', null, null, 'id', 'homebanner');
  elementVoid('div');
  elementClose('div');
  elementVoid('div', null, null, 'id', 'bannerfilter');
  elementOpen('div', null, null, 'class', 'topmenuouter');
  elementVoid('div', null, ['id', 'headsidemenu']);

  elementOpen('div', null, null, 'class', 'topmenubar');

  elementOpen('a', `head-logo`, null, 'href', '/', 'class', 'menubarlogolink', 'alt', 'Home Page',
    'onclick', (event) => {
      linkClick(event, '/');
    });
  headerLogo();
  elementClose('a');

  elementOpen('div', null, null, 'class', 'content', 'id', 'hmc');
  headMenuFormattedContent(headMenuContent, window.location.pathname, headMenuStore.shorten);
  elementClose('div');

  if (dropmenu) {
    rxnanoIcons.HamburgerIcon(() => {
      dropmenu.toggle();
    });
  }
  elementClose('div');

  if (typeof subMenu === 'function') {
    subMenu();
  } else {
    subMenuContent(subMenu, subMenuClass);
  }
  elementVoid('div', null, ['id', 'headsubmenu']);

  elementClose('div');

  homeHeroContent();

  elementClose('div');

  if (window.location.pathname === '/') {
    renderHomeBanner('banners/home2');
  }

  homeHeroContentLast('lower')

  // headMenuStore = {actual: 0, shorten: 0};
  // TODO: Start on first idle.

  timer(200)
    .subscribe(() => {
      const el = document.getElementById('hmc') as HTMLElement;
      patch(el,
        () => {
          headMenuFormattedContent(headMenuContent, window.location.pathname, headMenuStore.shorten);
          afterRepaint(() => setHeadMenuWidth(headMenuContent, 0));
        });
    });

  // patchElement('headsubmenu').style.height = '0px';
  // document.getElementById('headsidemenu').style.right = '-2000px';
  // headMenuStore = { // TODO: Check if this is needed still
  //     show: false,
  //     actual: 0
  // };
  // console.log('killing top menu')
  // headMenuStore = merge(headMenuStore, {show: false, actual: 0});



  // afterRepaint(() => setHeadMenuWidth(headMenuContent, 0));
  // window.requestAnimationFrame(() => setHeadMenuWidth(0));
  // addToPageQueue({
  //     run: function loadPix() {
  //         console.log('headMenuStore.shorten')
  //         afterRepaint(() => setHeadMenuWidth(headMenuContent, 0));
  //     }, name: 'hmcShorten', holdFor: [{
  //         event: KernelEvent.itemPatched,
  //         item: 'hmcContent'
  //     }]});

  let resizeThrottle = undefined as undefined | any; //TODO: fix type
  window.addEventListener('resize', (e) => {
    //   console.log('resize event')
    // NICE: remove flash after resize
    if (!isNil(resizeThrottle)) {
      clearTimeout(resizeThrottle);
    }
    resizeThrottle = setTimeout(() => {

      if (window.innerWidth !== windowWidth) { // Don't reflow if only height changes (iPad menubar reveal)
        windowWidth = window.innerWidth;
        headMenuShorten(-1);
        const hmc = document.getElementById('hmc') as Element;
        patch(hmc, () => headMenuFormattedContent(headMenuContent, window.location.pathname, headMenuStore.shorten));

        afterRepaint(() => setHeadMenuWidth(headMenuContent, 0));
        //   window.requestAnimationFrame(() => setHeadMenuWidth(0));
      }

      resizeThrottle = undefined;
      // topMenuShow(false);
    }, 100);
  });
};

const setHeadMenuWidth = (headMenuContent, loopctr: number = 0) => {

  // console.log(`Setting menu width hms: ${headMenuStore.shorten}`)
  let el = document.getElementById('mclist-head') as HTMLElement;
  if (el !== null) {
    let el2 = el.firstChild as HTMLElement;
    if (el2 !== null) {
      // console.log(`menu: ${el.offsetLeft} ${el2.offsetLeft}`);
      // console.log(`menu: ${el.offsetWidth} ${el2.offsetWidth}`);
      if (el2.offsetWidth > (el.offsetWidth - 40)) {
        // console.log('No room for menu');
        const hmc = document.getElementById('hmc') as Element;
        patch(hmc, () => { });
      } else if (el2.offsetLeft < el.offsetLeft) {
        // console.log(`xMenu Overflowing by: ${(el.offsetLeft-el2.offsetLeft)} loop: ${loopctr} hms: ${headMenuStore.shorten}`);
        headMenuShorten(1);
        const hmc = document.getElementById('hmc') as Element;
        patch(hmc, () => headMenuFormattedContent(headMenuContent, window.location.pathname, headMenuStore.shorten));
        afterRepaint(() => setHeadMenuWidth(headMenuContent, loopctr + 1));
        // window.requestAnimationFrame(() => setHeadMenuWidth(loopctr+1));
      }
    }
  }

};

export {
  HeadMenu,
  headMenuFormattedContent, // Used in scrollmenu
};

