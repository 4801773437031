import { PageContent, completeCallback, JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, render, patch, linkClick } from '@appzuka/rxnano';
import { first, map} from 'rxjs/operators';
import { sectionHeader } from 'site/components/sectionHeader/sectionHeader';

import './displayList.scss';

interface DisplayListItem {
    image: string,
    link?: string,
    imageTitle?: string,
    title?: string,
    content: () => void
    detail?: () => void
}

interface DisplayListOptions {
    sectionTitle?:string,
    filter?:string
}

const displayList = (items:DisplayListItem[], options:DisplayListOptions = {}) => {

    <render>
        { options.sectionTitle && sectionHeader(options.sectionTitle) }
        <div class='dl-wrapper constrain-width'>
        { () => items.filter(f => options.filter ? f.link !== options.filter : true).map((item,i) => {
            let imageel;
            <render>
                { () => {if (item.link) {
                    <render>
                        <a href={item.link}  class={`dl-item ${i%2===0 ? 'even-row' : 'odd-row'}`}
                          onclick={(event) => {
                            linkClick(event, item.link)}
                        }>
                            <div class='dl-image-wrapper'>
                                <div class={`dl-image-{i} dl-image`} ref={r => imageel = r}></div>
                                { item.imageTitle && <h2>{item.imageTitle}</h2>}
                            </div>
                            <div class={'dl-item-content'}>
                                { item.content }
                                <p class='dl-item-link'>Click here for more...</p>
                            </div>
                        </a>
                    </render>
                } else {
                    <render>
                        <div class='dl-item'>
                            <div class='dl-image-wrapper'>
                                <div class={`dl-image-{i} dl-image`} ref={r => imageel = r}></div>
                                { item.imageTitle && <h2>{item.imageTitle}</h2>}
                            </div>
                            <div class={'dl-item-content'}>
                                { item.content }
                            </div>
                        </div>
                    </render>
                }}

            }
            </render>
            if (i < items.length-1) {
                <render>
                    <div class='dl-list-divider'/>
                </render>

            }
            pixLoadedObservable
            .pipe(
            first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
            map(pl => pl.filter(p => p.file.match(new RegExp(`${item.image}$`)))), // Filter for the chosen image
            )
            .subscribe(pix => {
                patch(imageel, () => sitePix(exportedSiteData.assetBase, pix[0].file, { mediaSizes: '200px'}));
            });

        })}
        </div>
    </render>

}

export {
    displayList,
    DisplayListItem
}
