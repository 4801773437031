import { exportedSiteData, render } from '@appzuka/rxnano';

import { completeCallback, sitePix } from '@appzuka/rxnano';

import { SiteData } from 'site/siteContent';

import './insetImageBanner.scss';
import { patch } from 'incremental-dom';

import { JSXFactory, CustomElementHandler } from '@appzuka/rxnano';

const placeHolderSVG = (width, height, fill) => {
  return encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"><rect fill="${fill}" width="${width}" height="${height}"/></svg>`
  );
}

const insetImageBanner = (background, insetImage, title, subtitle) => {
    render(
        <div id="inset-image-banner-wrapper">
        <div id="pix-wrapper">
          {() => sitePix(exportedSiteData.assetBase, background, {
            mediaSizes: '(min-width: 500px) 100vw', id: `multi-aspect-pix`,
            minimum: 768
          })}
          <div id="pix-filter"></div>
        </div>
        <div class='about-banner-content'>
          {() => sitePix(exportedSiteData.assetBase, insetImage, {
            mediaSizes: '(max-width: 767px) 600w, 30vw', id: `inset-image-frame`,
            placeholder: placeHolderSVG(600, 480, '#999')
          })}
  
          <div class='about-statement-wrapper'>
            <div class='about-statement'>
              <div class='statement-container'>
                <h2>{title}</h2>
              </div>
              {/* <div class="sig-footer-wrapper">
                  </div> */}
            </div>
            <h2 class='about-name'>{subtitle}</h2>
          </div>
        </div>
        <div class="separator"></div>
      </div>
  
    );
  }

  export {
      insetImageBanner
  }
