import {
    elementOpen,
    elementVoid,
    elementClose,
    patch,
    text
} from 'incremental-dom';

import { pixLoadedObservable, exportedSiteData } from '@appzuka/rxnano';
// import { PageListItem } from 'src/basePages';

// import { first } from 'rxjs/operators';
// import { BehaviorSubject } from '@appzuka/rxnano/node_modules/rxjs';
import { first, map, takeWhile } from 'rxjs/operators';

// import './imageBanner.scss';

interface ServiceLinkItem {
    label: string
    shortLabel?: string
    link?: string
}

type bannerFunction = (any) => void;

type bannerTitle = string | Function | Node;

interface PageListItem {
    pageLocation: string
    content: any
    image: bannerFunction | string | [string, bannerTitle]
    pageTitle: string
    subMenu?: ServiceLinkItem[]
    name?: string
    index?: boolean
    hide?: boolean
}

// const ImageBanner = (pageChangeObservable) => {
//     const ib = Object.create({});

//     // console.log('Installing Imagebanner')

//     const bwel = document.createElement('div');
//     bwel.setAttribute('id', 'bannerwrapper');
//     const pw = document.getElementById('pagewrapper');
//     pw!.parentElement!.insertBefore(bwel, pw);

//     // FIXME: Auto install div

//     pageChangeObservable.subscribe({
//         next: (ps:PageListItem) => {
//             const im = ps.image;
//             if (typeof im === 'function') {
//                 const el  = document.getElementById('bannerwrapper') as HTMLElement;  // Assume can never be null
//                 patch(el, () => im(pageChangeObservable));
//                 el.setAttribute('class', 'functionbanner');
//             } else if (Array.isArray(im)) {
//                 renderImageBanner(...im);
//             } else {
//                 throw new Error('string imagebanner')
//             }
//         }
//     });

//     return ib;
// }


const renderHomeBanner = (imageName, breakpoints=[550, 768, 1024, 1200]) => {

    // performance.mark('rawouter-start');

    // The bannerwrapper div is added to every page in the markup

    // If imageName is '' remove the banner contents and class attributes but leave the div
    // if (imageName.length === 0) {
    //     const elm  = document.getElementById('bannerwrapper') as HTMLElement;  // Assume can never be null
    //     while (elm.hasChildNodes()) {
    //         elm.removeChild(elm.lastChild as Node);
    //       }
    //     if (elm.hasAttribute('class')) {
    //         elm.removeAttribute('class');
    //     }
    //     return;
    // }

    const multiPix = (ap4, pel) => {

        let variantArray = [
            {variant: 'mobile', media: '(max-width: 550px)'},
            {variant: 'portrait', media: '(min-width: 551px) and (max-width: 768px)'},
            {variant: 'wide', media: '(min-width: 769px) and (max-width: 1023px)'},
            {variant: 'pano', media: '(min-width: 1024px)'}
        ];

        if (ap4.length > 0) {
            const defaultPix = ap4.filter(p => ((p.file === imageName) && (p.variant === 'wide')))[0];
            const defaultSize = defaultPix.sizes.sort((a, b) => { return ((a - 1024) ** 2 < (b - 1024) ** 2) ? -1 : +1 })[0]; // The size closest to 1024

            variantArray.map(({variant, media}, i) => {
                const mobilePixArray = ap4.filter(p => ((p.file === imageName) && (p.variant === variant)))
                if (mobilePixArray.length > 0) {
                    const mobilePix = mobilePixArray[0];
                    const imageBase = `${exportedSiteData.assetBase}${mobilePix.pathprefix}/${[mobilePix.file, mobilePix.variant, mobilePix.hash].filter(p=>p).join('-')}`
                    const webpSrc = mobilePix.sizes.map(s => `${imageBase}-${s}.webp ${s}w`).join();
                    const jpgSrc = mobilePix.sizes.map(s => `${imageBase}-${s}.jpg ${s}w`).join();

                    let ps = document.createElement('source');
                    ps.setAttribute('type', 'image/webp');
                    ps.setAttribute('srcset', webpSrc);
                    ps.setAttribute('media', media);

                    pel.appendChild(ps);
                    
                    let psj = document.createElement('source');
                    psj.setAttribute('type', 'image/jpeg');
                    psj.setAttribute('srcset', jpgSrc);
                    psj.setAttribute('media', media);

                    pel.appendChild(psj);
                }
            });

        // if (ap4.length > 0) {
        //     const defaultPix = ap4.filter(p => p.file.indexOf(imageArray[Math.ceil(imageArray.length / 2) - 1]) >= 0)[0]; // The middle image TODO: Error checking
        //     const defaultSize = defaultPix.sizes.sort((a, b) => { return ((a - 1024) ** 2 < (b - 1024) ** 2) ? -1 : +1 })[0]; // With the size closest to 1024
    
        //     // console.log(imageArray)

        //     imageArray.map((file, i) => {
        //         const mobilePixArray = ap4.filter(p => p.file.indexOf(file) >= 0);
        //         if (mobilePixArray.length > 0) {
        //             const mobilePix = mobilePixArray[0];
        //             const webpSrc = mobilePix.sizes.map(s => `${exportedSiteData.assetBase}${mobilePix.pathprefix}/${mobilePix.file}-${mobilePix.hash}-${s}.webp ${s}w`).join();
        //             const jpgSrc = mobilePix.sizes.map(s => `${exportedSiteData.assetBase}${mobilePix.pathprefix}/${mobilePix.file}-${mobilePix.hash}-${s}.jpg ${s}w`).join();

        //             let media;
        //             if (i == 0) {
        //                 media = `(max-width: ${breakpoints[i]}px)`;
        //             } else if (i === imageArray.length - 1) {
        //                 media = `(min-width: ${breakpoints[i - 1] + 1}px)`;
        //             } else {
        //                 media = `(min-width: ${breakpoints[i - 1] + 1}px) and (max-width: ${breakpoints[i]}px)`;
        //             };
        //             let ps = document.createElement('source');
        //             ps.setAttribute('type', 'image/webp');
        //             ps.setAttribute('srcset', webpSrc);
        //             ps.setAttribute('media', media);

        //             pel.appendChild(ps);
                    
        //             let psj = document.createElement('source');
        //             psj.setAttribute('type', 'image/jpeg');
        //             psj.setAttribute('srcset', jpgSrc);
        //             psj.setAttribute('media', media);

        //             pel.appendChild(psj);
        //         }
        //     });

            let im = document.createElement('img');
            im.setAttribute('src', `${exportedSiteData.assetBase}${defaultPix.pathprefix}/${[defaultPix.file, defaultPix.variant, defaultPix.hash, defaultSize].join('-')}.jpg`);
            pel.appendChild(im);

        } else {
            // performance.mark('ww-start');
            const w = window.innerWidth;
            const h = w<550 ? 0.667 : w<1024 ? 0.333 : 0.25;
            let im = document.createElement('img');
            const svginline = `data:image/svg+xml;utf8,<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 680 ${680*h}"><rect width="680" height="${680*h}"  style="fill:%23eee" /></svg>`;
            im.setAttribute('src', svginline);
            pel.appendChild(im);
            // performance.mark('ww-end');
            // performance.measure('wwMarker', 'ww-start', 'ww-end');
        }
    }

    const createPictureFragment = (ap3) => {
        var fragment = document.createDocumentFragment();
        let pel = document.createElement('picture');
        pel.setAttribute('id', 'multi-aspect-pix');
        multiPix(ap3, pel);
        fragment.appendChild(pel);
        return fragment;
    }

    // const createTitleFragment = (title: string | HTMLDivElement): HTMLDivElement => {

    //     if (title === undefined) {
    //         let twrap = document.createElement('div') as HTMLDivElement;
    //         return twrap;
    //     };
    //     if (typeof title !== 'string') { return title };

    //     let twrap = document.createElement('div') as HTMLDivElement;
    //     twrap.setAttribute('class', 'hero-image-title');
    //     let hel = document.createElement('h2');
    //     hel.setAttribute('class', 'hero-image-text hero-image-text-gradient');
    //     let tel = document.createElement('text');
    //     hel.textContent = title;
    //     twrap.appendChild(hel);
    //     return twrap;
    // }

    const el  = document.getElementById('homebanner') as HTMLElement;  // Assume can never be null
    if (el === null) {
        throw new Error('custom utils.ts: No homebanner'); // FIXME: Send message to error logger without throwing
    }
    const className = el.getAttribute('class');

    // if (className !== 'photobanner') {
    //     while (el.hasChildNodes()) {
    //         el.removeChild(el.lastChild as Node);
    //     }

    //     const fragment = createPictureFragment([]); // Create data_svg placeholder
    //     el.appendChild(fragment);
    //     el.appendChild(createTitleFragment(title));
    //     el.setAttribute('class', 'photobanner');
    // }

    // if (typeof title === 'string') {
        // (el).replaceChild(createTitleFragment(title), (el).childNodes[1] as Node);
    // } else {
    //     console.log('appending title2');
    //     console.log((el).childNodes[1]);
    //     (el).replaceChild(title, (el).childNodes[1]);
    // }

    // (el).replaceChild(createTitleFragment(title), (el).childNodes[1]);

    // pixLoadedObservable
    // .pipe(
    //     first(pl => pl.length > 0), // Wait until pixlist is loaded

    // )

    // Argument of type
    // 'import("/home/nick/code/dean/node_modules/rxjs/internal/types")
    // .OperatorFunction<import("/home/nick/code/dean/node_modules/@appzuka/rxnano/pixList").SitePix[],
    // import("/home/nick/code/dean/node_modules/@appzuka/rxnano/pixList").SitePix[] | undefined>'

    // 'import("/home/nick/code/dean/node_modules/@appzuka/rxnano/node_modules/rxjs/internal/types")
    // .OperatorFunction<import("/home/nick/code/dean/node_modules/@appzuka/rxnano/pixList").SitePix[],
    // import("/home/nick/code/dean/node_modules/@appzuka/rxnano/pixList").SitePix[] | undefined>'.


    // Replace the image once the pixlist is availablex
    pixLoadedObservable
    .pipe(
        first(a => a.length > 0)
    )
    .subscribe(ap => {
        const fragment = createPictureFragment(ap);
        (el).replaceChild(fragment, (el).firstChild as Node);
    })
}

export {
    renderHomeBanner
};
