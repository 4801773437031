import { PageContent, completeCallback, JSXFactory, render, exportedSiteData, sitePix } from '@appzuka/rxnano';
import { PlaceholderBlock, PlaceholderPageBanner, PlaceholderMultiBlock } from 'site/placeholders/placeholders';

import { displayList, DisplayListItem} from 'site/components/displayList/displayList';
import { servicesSummaryData } from 'site/contents/services';
import { insetImageBanner } from 'site/components/insetImageBanner/insetImageBanner';

const ServicesContent = <>
<h2>Contract and Public Services</h2>
<p>Overland have been providing a comprehensive tree management service to local authorities and Bluechip companies (Southern Electric) for over 15 years.</p>
<h2>Commercial</h2>
<p>Overland additionally work with many commercial sector businesses offering a fast and dependable service which incorporates the delivery and installation of industrial hoardings, security fencing and site clearance.</p>
<h2>Residential & Private</h2>
<p>Based in Hampshire, Overland have been called upon by homeowners and private clients to provide tree work locally and throughout the South of England.
Our work includes hedge, tree and general grounds maintenance, tree felling and stump removal, new tree planting and all forms of garden fencing.</p>
</>;

const PortfolioData = [
  {
    name: 'project1',
    title: 'Project 1',
    imageBefore: 'projects/before1',
    imageAfter: 'projects/after1',
    bullets: [
      'Scrub Clearance',
    ],
    content: undefined,
    link: '/portfolio/project1',
  },
  {
    name: 'project2',
    title: 'Project 2',
    imageBefore: 'projects/before2',
    imageAfter: 'projects/after2',
    bullets: [
      'Hedge Cutting',
    ],
    content: undefined,
    link: '/portfolio/project2',
  },
];

const portfolioList:DisplayListItem[] = PortfolioData.map(p => {
  return({
    image: p.imageAfter,
    link: p.link,
    content: () => {
      <render>
        <h2>{p.title}</h2>
      </render>
    }
  })

});

// const PortfolioList = (skip?: string) => {
//   PlaceholderMultiBlock(`${skip?'Other ':''}Portfolio`, undefined, 
//   PortfolioData.filter(e => e.title !== skip).map((s,i) => {
//     // if (ap.link) {
//       return () => render(<a href={s.link} class={`carousel-block carousel-image-block ${i===0?' first-block':''}`}>
//       <h2>{s.title}</h2>
//       {() => sitePix(exportedSiteData.assetBase, s.imageAfter, { mediaSizes: '400px'})}
//       <ul>
//         { () => {
//           s.bullets.map(b => {
//             render(<li><p>{b}</p></li>)
//           })
//         }}
//       </ul>
//     </a>)
//     // } else {
//     //   return () => render(<div class={`carousel-block carousel-image-block ${i===0?' first-block':''}`}>
//     //   <h2>{ap.title}</h2>
//     //   {() => sitePix(exportedSiteData.assetBase, ap.image, { mediaSizes: '400px'})}
//     //   <p>Daily or weekly hire available</p>
//     //   <p>£ POA</p>
//     //   <p>Click for further details</p>
//     // </div>)
//     // }
//   }))
// };

const servicePages : PageContent = {
  content: [
      {
        pageLocation: 'services',
        image: () => insetImageBanner(`banners/forest`, `insetbanner/treesurgery`, 'Overland Services', ''),
        pageTitle: 'Services',
        content: 
            <>
            <div class='constrain-width'>
              <h2>Contract and Public Services</h2>
              <p>Overland have been providing a comprehensive tree management service to local authorities and Bluechip companies (Southern Electric) for over 15 years.</p>
              <h2>Commercial</h2>
              <p>Overland additionally work with many commercial sector businesses offering a fast and dependable service which incorporates the delivery and installation of industrial hoardings, security fencing and site clearance.</p>
              <h2>Residential & Private</h2>
              <p>Based in Hampshire, Overland have been called upon by homeowners and private clients to provide tree work locally and throughout the South of England.
  Our work includes hedge, tree and general grounds maintenance, tree felling and stump removal, new tree planting and all forms of garden fencing.</p>
            </div>
          { () => displayList(servicesSummaryData, {sectionTitle: 'Overland Services'}) }
          { () => displayList(portfolioList, {sectionTitle: 'Overland Portfolio'}) }
            </>
      },
      {
        pageLocation: /services\/(.*)/,

        image: () => {
          // console.log(pv)
          const matches = window.location.pathname.match(/services\/(.*)/);
          const thisItem = servicesSummaryData.find(n => n.name === matches![1]);

          if (thisItem) {
            render(() => insetImageBanner(`banners/forest`, `insetbanner/${thisItem.name}`, thisItem.imageTitle, ''))
          }
        },
        pageTitle: 'Services',
        content: () => {
          const matches = window.location.pathname.match(/services\/(.*)/);
          const thisItem = servicesSummaryData.find(n => n.name === matches![1]);

          if (thisItem) {
            render(<>
              <div class='constrain-width'>
                <h2>{thisItem.imageTitle}</h2>
                { thisItem.content }
              </div>
                { () => displayList(servicesSummaryData, {sectionTitle: 'Overland Services', filter: thisItem.link}) }
            </>
);
          } else {
            render(<div class='constrain-width'>
              <p>Service {matches![1]} not found</p>
              { () => displayList(servicesSummaryData, {sectionTitle: 'Overland Services'}) }
              </div>);
          }


        }
      },
  ],
  subMenu: [
    { label: 'Services', link: '/services' },
    { label: 'Ground Maintenance', link: '/services/ground' },
    { label: 'Tree Surgery', link: '/services/treesurgery' },
    { label: 'Equipment Maintenance', link: '/services/maintenance' },
  ]
}
const portfolioPages : PageContent = {
    content: [
        {
          pageLocation: 'portfolio',
          image: {imageName: 'banners/aerial'},
          pageTitle: 'Portfolio',
          content: 
              <>
            { () => PlaceholderBlock(undefined, undefined, <div>
                <p>Some Examples Of Our Trade Skills And Workmanship</p>
              </div>)}
            { () => displayList(portfolioList, {sectionTitle: 'Overland Portfolio'}) }
            { () => displayList(servicesSummaryData, {sectionTitle: 'Overland Services'}) }

              </>
        },
        {
          // pageLocation: 'aerialplatforms/:platform',
          pageLocation: /portfolio\/(.*)/,
          image: {imageName: 'banners/aerial'},
          // image: () => {
          //   // console.log(pv)
          //   const matches = window.location.pathname.match(/portfolio\/(.*)/);
          //   const thisItem = PortfolioData.find(n => n.name === matches![1]);
  
          //   if (thisItem) {
          //     render(() => PlaceholderPageBanner(`Portfolio: ${thisItem!.title}`))
          //   }
          // },
          pageTitle: 'Portfolio',
          content: () => {
            const matches = window.location.pathname.match(/portfolio\/(.*)/);
            const thisItem = PortfolioData.find(n => n.name === matches![1]);
  
            if (thisItem) {
              render(<div>
                { thisItem!.content !== undefined ? thisItem!.content : undefined }
                {() => sitePix(exportedSiteData.assetBase, thisItem!.imageBefore, { mediaSizes: '800px'})}
                {() => sitePix(exportedSiteData.assetBase, thisItem!.imageAfter, { mediaSizes: '800px'})}
                <ul>
                  { () => {
                    thisItem!.bullets.map(b => {
                      render(<li><p>{b}</p></li>)
                    })
                  }}
                  </ul>
                  { () => displayList(portfolioList, {sectionTitle: 'Other Projects from Our Portfolio', filter: thisItem.link}) }
                  { () => displayList(servicesSummaryData, {sectionTitle: 'Overland Services'}) }
              </div>);
          } else {
            render(<>
              <p>Project {matches![1]} not found</p>
              { () => displayList(portfolioList, {sectionTitle: 'Overland Portfolio'}) }
            { () => displayList(servicesSummaryData, {sectionTitle: 'Overland Services'}) }
              </>);
          }

          }
        }
    ],
    subMenu: [
      { label: 'Portfolio', link: '/portfolio' },
      { label: 'Project 1', link: '/portfolio/project1' },
      { label: 'Project 2', link: '/portfolio/project2' },
    ]
}

export {
    servicePages,
    portfolioPages
};

