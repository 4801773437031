
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('h2');text(`PRIVACY POLICY`);elementClose('h2');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('This policy explains how Overland Environmental Services uses the personal information they collect to distribute products and communications directly related to the work we undertake. It describes how long the information is kept and how we use the data regarding third parties.')
elementClose('p');
elementClose('div');
elementOpen('h3');text(`How do we collect information?`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We obtain information about you in a variety of ways including when you contact us about our services, complete an agreement with us, make an enquiry to us or visit our website.')
elementClose('p');
elementClose('div');
elementOpen('h3');text(`What information do we collect?`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('OES holds the following data which allows us to communicate with you about our service to you:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Your consent or refusal to complete works`);elementClose('li');
elementOpen('li');text(`Your address and contact information (email/phone/name)`);elementClose('li');
elementOpen('li');text(`Customer communication records. Communications made regarding enquiries, complaints or transactions for audit purposes.`);elementClose('li');
elementClose('ul');
elementClose('div');
elementOpen('h3');text(`Length of time data will be held`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('In compliance with the General Data Protection Regulations, your details will only be kept for the shortest time required. This will vary according to the type of data being held.')
elementClose('p');
elementClose('div');
elementOpen('h3');text(`Keeping your data secure`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('The data we hold in emails, invoices and administrative tools to ensure completion is stored securely within our premises at all times. Our policies and procedures are reviewed periodically to ensure that compliance is maintained.')
elementClose('p');
elementOpen('p');
text('We do not store entire credit/debit card numbers, nor do we keep records of the security code of customers’ credit or debit cards. These details will be requested during the processing of specific transactions.')
elementClose('p');
elementClose('div');
elementOpen('h3');text(`Who has access to your information?`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We may pass your details onto 3rd party providers who are work with Overland Environmental Services in the course of delivering our services. These 3rd parties are obliged to keep your details securely and will use them only to fulfil the service.')
elementClose('p');
elementOpen('p');
text('Third parties that may have access to your information:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Overland ES – Manage our own website, Terms and Conditions and Privacy & Cookie Policy.`);elementClose('li');
elementOpen('li');text(`Sage – This will be used for the creation of invoices.`);elementClose('li');
elementOpen('li');text(`DNO’s – Should any services provided to you potentially affect any network equipment, then they will have to be notified.`);elementClose('li');
elementClose('ul');
elementOpen('p');
text('We will never collect sensitive information about you without your explicit consent for each category.')
elementClose('p');
elementOpen('p');
text('Please note that by agreeing to share any information with us you have not forfeited your rights as prescribed under the Data Protection Act 1998 and Overland Environmental Services will continue to apply the same level of care to safeguard your privacy and use of your information. Your service entitlement from Overland Environmental Services will not be affected should you decide not to allow your data to be shared in any way, or if you change your mind at any time in the future.')
elementClose('p');
elementClose('div');
elementOpen('h3');text(`How is your information used?`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('OES may use the information you provide us to unless you tell us that we may not to do so:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Respond to your enquiries;`);elementClose('li');
elementOpen('li');text(`Carry out our obligations arising from any contract or agreement entered by you or us;`);elementClose('li');
elementOpen('li');text(`Communicate with you about the service we are providing;`);elementClose('li');
elementOpen('li');text(`Tell you about the services we provide;`);elementClose('li');
elementOpen('li');text(`Seek your views or comments on the services we provide you;`);elementClose('li');
elementOpen('li');text(`Notify you of changes to our services;`);elementClose('li');
elementOpen('li');text(`Support our activities on your behalf;`);elementClose('li');
elementOpen('li');text(`For marketing purposes`);elementClose('li');
elementClose('ul');
elementClose('div');
elementOpen('h3');text(`Subject Access Requests:`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Overland Environmental Services will be the data controller and the contact details for the company are Data Protection Officer: Rob Nash, Email: rob@overland-es.com Tel: 0118 981 4297')
elementClose('p');
elementOpen('p');
text('You have the right to request a copy of the information that we hold about you.  If you would like a copy of some or all of your personal information, please email us or write to us using the contact details above.  We will provide the information within one month of receipt of the request.  By law, we are required to verify your identity. We may make a small charge for this service if the request is excessive or repetitive or requiring further copies of the same information.')
elementClose('p');
elementOpen('p');
text('We want to make sure that your personal information is accurate and up to date.  You may ask us to correct or remove information you think is inaccurate.  Under the Data Protection Act 1998, you can make a formal request for information including:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`clarification that your personal data are being processed by the Company;`);elementClose('li');
elementOpen('li');text(`a description and copies of such personal data;`);elementClose('li');
elementOpen('li');text(`the reasons why such data are being processed;`);elementClose('li');
elementOpen('li');text(`details of to whom they are or may be disclosed.`);elementClose('li');
elementClose('ul');
elementClose('div');
elementOpen('h3');text(`Processing of Data:`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Under the GDPR you have the following rights to request information from the company:')
elementClose('p');
elementOpen('ul');
elementOpen('li');text(`Right of access to the data (Subject Access Request)`);elementClose('li');
elementOpen('li');text(`Right for the rectification of errors`);elementClose('li');
elementOpen('li');text(`Right to erasure of personal data (please note, this is not an absolute right)`);elementClose('li');
elementOpen('li');text(`Right to restriction of processing or to object to processing`);elementClose('li');
elementOpen('li');text(`The right to portability`);elementClose('li');
elementClose('ul');
elementOpen('p');
text('You have the right to lodge a complaint with a supervisory authority (in the UK that is the Information Commissioners Office).')
elementClose('p');
elementClose('div');
elementOpen('h3');text(`Changes to our Privacy Policy`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('We keep this policy under regular review and any updates will be recorded and made available.')
elementClose('p');
elementClose('div');
  elementClose('div');
  return el;
};