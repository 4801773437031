import { PageContent, completeCallback, JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, render, patch } from '@appzuka/rxnano';
import { PlaceholderCarousel, PlaceholderBlock, PlaceholderMultiBlock } from 'site/placeholders/placeholders';
import { displayList, DisplayListItem } from 'site/components/displayList/displayList';
import { arbTruckBanner } from 'site/components/arbTruckBanner/arbTruckBanner';
import { sectionHeader } from 'site/components/sectionHeader/sectionHeader';
import { first, map} from 'rxjs/operators';
import { isConstructorDeclaration } from 'typescript';

import { newsSummaryData } from 'site/contents/news';
import { servicesSummaryData } from 'site/contents/services';
import { woodchippersToBuyData } from 'site/contents/woodchippers';

import './home.scss';


let ebayScriptAdded = false;
declare global {
  interface Window { AN_Item_JS: any; AN_Item: any, AN_Item_ready: any }
}

const ebayItems = () => {
  render(<div id="auction-nudge-items" class="auction-nudge"></div>);
  if (!ebayScriptAdded) {
    // console.log('** Adding ebay script')

    ebayScriptAdded = true;
    let newScript = document.createElement("script");
    newScript.setAttribute("type", "text/javascript");

    newScript.onload = () => {
      // console.log('script loaded');
      // console.log(window.AN_Item_JS)
      // console.log(window.AN_Item)

    };
    newScript.src = 'https://www.auctionnudge.com/feed/item/js/theme/responsive/page/init/img_size/120/cats_output/dropdown/search_box/1/show_logo/1/lang/english/SellerID/overland-es/siteid/3/MaxEntries/6';
    document.getElementsByTagName("body")[0].appendChild(newScript);
  } else {
    // console.log('** ebay script already present')
    // console.log(window.AN_Item)
    // window.AN_Item.i('auction-nudge-items');
    window.AN_Item.r();
  }

}

const homePages : PageContent = {
    content: [
        {
            pageLocation: '',
            image: () => {},
            pageTitle: 'Home',
            content: () => {
                // const sp = getStorePix(exportedSiteData.assetBase, 'fullpixlist.json');
                // console.log(sp)
                arbTruckBanner();
                <render>
                  <div>
                  <div class='constrain-width'>
                  <h2>
About Us
</h2>
<p>Overland are innovative leaders within the Arboriculture industry, we design and build our very own Arb Truck Tippers based on the very popular, Toyota Hilux, Ford Transit and Iveco Daily.</p>
<p>We additionally sell and hire all the specialist equipment required by tree surgeons to complete their work.</p>
<p>The Schliesing professional wood chippers we supply are arguably the best in the industry and
we also have in stock varying height CMC Aerial platforms (MEWP’s) either truck mounted or on tracks for the more challenging of terrains.</p>
</div>
                { () => {

                displayList(servicesSummaryData, {sectionTitle: 'Overland Services'});
                displayList(woodchippersToBuyData, {sectionTitle: 'Woodchippers to Buy'});
                sectionHeader('eBay Items for Sale')
                }}
                <div class='constrain-width'>
                  { ebayItems }
                </div>
                { () => {
                  sectionHeader('Financial Services');
                }}
                <div class='constrain-width home-fin-services'>
                  { () => sitePix(exportedSiteData.assetBase, 'services/finance', { mediaSizes: '1100px'}) }
                  <div class='home-fin-services-content'>
                      <ul>
                        <li>Hire Purchase</li>
                        <li>Finance Lease</li>
                        <li>Long & Short Term Hire</li>
                      </ul>
                    </div>
                </div>

                { () => {
                displayList(newsSummaryData, {sectionTitle: 'Recent News from Overland'});

                }}

                  </div>
                </render>


                completeCallback('home');
            }
        }
    ],
    subMenu: []
};

export {
    homePages
};

