import { JSXFactory, sitePix, exportedSiteData } from '@appzuka/rxnano';
import { DisplayListItem } from 'site/components/displayList/displayList';


interface VehicleDisplayListItem extends DisplayListItem {
    name: string
    hire: boolean
    buy: boolean
  };

export const vehicleSummaryData:VehicleDisplayListItem[] = [
// {
//     name: 'arbtruck',
//     hire: false,
//     buy: true,
//     image: 'vehicles/arbtruck/front-off-side',
//     link: '/vehicles/arbtruck',
//     imageTitle: 'ArbTruck',
//     title: 'ArbTruck - Exclusively from Overland',
//     content: <div class='dl-news-content'>
//     <h2>Exclusively from Overland</h2>
//     <p>Custom ArbTruck with Options Available on Request</p>
//     </div>,
//     detail: <div>
//     <h2>Exclusively from Overland</h2>
//     <p>For Sale: £32,500</p>
//     <p>Options Fitted</p>
//     <ul>
//     <li>Single cab wide body Hilux</li>
//     <li>Bespoke Tool box with Roller Shutters (colour coded)</li>
//     <li>Front and Rear bumpers</li>
//     <li>Hidden winch with synthetic rope</li>
//     <li>Wheel spacers</li>
//     <li>Two inch lift kit fitted to front</li>
//     <li>All terrain tyres</li>
//     <li>Adjustable air suspension fitted to the rear</li>
//     <li>Matt black Roll Bar fitted with LED Beacons & LED Light bar</li>
//     <li>Front and rear light protectors</li>
//     </ul>
//     <p>Many Additional Options Available on Request</p>
// </div>
// },
{
    name: 'toyota',
    hire: true,
    buy: true,
    image: 'vehicles/toyota',
    link: '/arbtrucks/toyota',
    imageTitle: 'Toyota',
    title: 'Toyota Hilux 3.5T ARBTRUCK Tipper Body',
    content: <div class='dl-news-content'>
    <h2>Toyota Hilux 3.5T ARBTRUCK Tipper Body</h2>
    <p>Our most popular Arbtruck conversion.</p>
    <p>The Toyota Hilux is available in single and double cab.</p>
    </div>,
        detail: <div>
        <p>Our most popular Arbtruck conversion.</p>
        <p>The Toyota Hilux is available in single and double cab.</p>
        <p>Shown here in single cab with additional tool storage.</p>
        <h2>Key Features:</h2>
        <ul>
        <li>Available in Single or Extra Cab</li>
        <li>With or without toolboxes</li>
        <li>Steel Framed - Aluminium planked tipping body</li>
        <li>Rear Barn Doors - Secondary Barn Doors Optional</li>
        <li>Ladder Racks</li>
        <li>Tow Pack - ball and pin Optional</li>
        <li>3.5t Towing capacity</li>
        <li>Can be upgraded to 3.5t GVW and 7.0t GTW</li>
        <li>Adjustable air bag suspension Optional</li>
        <li>All Terrain Tyres Optional</li>
        <li>LED work lights - LED Beacons - Reversing Camera - Chapter 8 all Optional</li>
        </ul>
        <p>All tippers are designed individually for bespoke needs: please contact us to discuss your requirements!</p>
    </div>
},
// {
//     name: 'transit',
//     hire: true,
//     buy: true,
//     image: 'vehicles/transit',
//     link: '/vehicles/transit',
//     imageTitle: 'Transit',
//     title: 'Ford Transit',
//     content: <div class='dl-news-content'>
//         <h2>Ford Transit</h2>
//     <p>The FORD Transit is another very popular conversion and no wonder, when you marry FORD reliability with large tipper capacity, you have the perfect partnership.</p>
//     </div>,
//     detail: <div>
//     <p>The FORD Transit is another very popular conversion and no wonder, when you marry FORD reliability with large tipper capacity, you have the perfect partnership.</p>
//     </div>
// },
{
    name: 'iveco',
    hire: true,
    buy: true,
    image: 'vehicles/iveco',
    link: '/arbtrucks/iveco',
    imageTitle: 'Iveco',
    title: 'Ford Iveco Daily',
    content: <div class='dl-news-content'>
        <h2>Ford Iveco Daily</h2>
        <p>Extra cab space and extra carrying capacity, a truly winning combination.</p>
    </div>,
    detail: <div>
        <p>Extra cab space and extra carrying capacity, a truly winning combination.</p>
    </div>
},
{
    name: 'fuso',
    hire: true,
    buy: true,
    image: 'vehicles/fuso',
    link: '/arbtrucks/fuso',
    imageTitle: 'Fuso',
    title: 'Mitsubishi Fuso',
    content: <div class='dl-news-content'>
        <h2>Mitsubishi Fuso</h2>
        <p>Another winning combination of versatility and utility.</p>
        <p>Offering a compact cab with large capacity loading and still room for tool storage.</p>
    </div>,
    detail: <div>
        <p>Another winning combination of versatility and utility.</p>
        <p>Offering a compact cab with large capacity loading and still room for tool storage.</p>
    </div>
}
];

export const vehicleDetail = [
    // {
    // name: 'ArbTruck',
    // link: 'arbtruck',
    // title: 'ArbTruck Limited Edition',
    // image: 'vehicles/arbtruck/front-off-side',
    // content: <div>
    //     <h2>Exclusively from Overland</h2>
    //     <p>For Sale: £32,500</p>
    //     <p>Options Fitted</p>
    //     <ul>
    //     <li>Single cab wide body Hilux</li>
    //     <li>Bespoke Tool box with Roller Shutters (colour coded)</li>
    //     <li>Front and Rear bumpers</li>
    //     <li>Hidden winch with synthetic rope</li>
    //     <li>Wheel spacers</li>
    //     <li>Two inch lift kit fitted to front</li>
    //     <li>All terrain tyres</li>
    //     <li>Adjustable air suspension fitted to the rear</li>
    //     <li>Matt black Roll Bar fitted with LED Beacons & LED Light bar</li>
    //     <li>Front and rear light protectors</li>
    //     </ul>
    //     <p>Many Additional Options Available on Request</p>
    // </div>},
    {
        name: 'Toyota',
        link: 'toyota',
        title: 'Toyota Hilux 3.5T ARBTRUCK Tipper Body',
        image: 'vehicles/toyota',
    content: <div>
        <p>Our most popular Arbtruck conversion.</p>
        <p>The Toyota Hilux is available in single and double cab.</p>
        <p>Shown here in single cab with additional tool storage.</p>
        <h2>Key Features:</h2>
        <ul>
        <li>Available in Single or Extra Cab</li>
        <li>With or without toolboxes</li>
        <li>Steel Framed - Aluminium planked tipping body</li>
        <li>Rear Barn Doors - Secondary Barn Doors Optional</li>
        <li>Ladder Racks</li>
        <li>Tow Pack - ball and pin Optional</li>
        <li>3.5t Towing capacity</li>
        <li>Can be upgraded to 3.5t GVW and 7.0t GTW</li>
        <li>Adjustable air bag suspension Optional</li>
        <li>All Terrain Tyres Optional</li>
        <li>LED work lights - LED Beacons - Reversing Camera - Chapter 8 all Optional</li>
        </ul>
        <p>All tippers are designed individually for bespoke needs: please contact us to discuss your requirements!</p>
    </div>
    },
    // {
    //     name: 'Transit',
    //     link: 'transit',
    //     title: 'Ford Transit',
    //     image: 'vehicles/transit',
    // content: <div>
    //     <h2>Ford Transit</h2>
    //     <p>The FORD Transit is another very popular conversion and no wonder, when you marry FORD reliability with large tipper capacity, you have the perfect partnership.</p>
    // </div>
    // },
    {
        name: 'Iveco',
        link: 'iveco',
        title: 'Ford Iveco Daily',
        image: 'vehicles/iveco',
    content: <div>
        <p>Extra cab space and extra carrying capacity, a truly winning combination.</p>
    </div>
    },
    {
        name: 'Fuso',
        link: 'fuso',
        title: 'Mitsubishi Fuso',
        image: 'vehicles/fuso',
    content: <div>
        <p>Another winning combination of versatility and utility.</p>
        <p>Offering a compact cab with large capacity loading and still room for tool storage.</p>
    </div>
    }
];
