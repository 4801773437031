import { JSXFactory } from '@appzuka/rxnano';
import { DisplayListItem } from 'site/components/displayList/displayList';

export const newsSummaryData:DisplayListItem[] = [
{
    image: 'posts/stagev',
    link: '/about/news/stagev',
    imageTitle: 'Stage V',
    content: <div class='dl-news-content'>
    <p>Stage V legislation represents a big change to the engines used in non-road mobile machinery, such as wood chippers, stump grinders, mowers, and skid steer machinery.</p>
    <p>Learn about the changes here.</p>
    </div>
},
{
    image: 'posts/customerstory',
    link: '/about/news/customerstory',
    imageTitle: 'A Customer Story',
    content: <div class='dl-news-content'>
        <p>While at the Overland head office, our customer was taken step by step through the order process, from bespoke personalisation to construction, build and testing.</p>
<p>So pleased with the overall professionalism of the company and staff, not forgetting the Arbtruck product, our customer placed his order there and then.</p>
    </div>
},
{
    image: 'posts/arbtruck',
    link: '/about/news/arbtruck',
    imageTitle: 'ArbTruck',
    content: <div class='dl-news-content'>
<p>Another Overland wide bodied Hilux Arb Truck off to a happy customer.</p>
<p>This particular customer wanted a Bespoke tool box with pull out drawers capable of carrying a variety of tools. Fitted with a winch bumper and a remote control Warn winch.</p>
<p>At Overland we offer a range of Arb Truck and are happy to build Bespoke trucks to suit specific needs.</p>
    </div>
},

];
