import { PageContent, completeCallback, JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, render, patch, linkClick } from '@appzuka/rxnano';
import { first, map} from 'rxjs/operators';
import { metalBlock } from 'site/components/metalBlock/metalBlock';

import arbTruckPix from './front-off-side.png';

import './arbTruckBanner.scss';

const leftPanel = () => {
    <render>
        <div>
          {() => sitePix(exportedSiteData.assetBase, `vehicles/toyota`, { mediaSizes: '200px'})}
        </div>
    </render>
}
const rightPanel = () => {
    <render>
        <div class='right-content'>
            <h2>ArbTruck</h2>
            <p>The Arbtruck tipper conversion from Overland has been created over many years by our highly experienced Arborists and has proven itself to be a reliable, steadfast, easy to use, essential work tool.</p>
        </div>
    </render>
}

const arbTruckBanner = () => {
    metalBlock(leftPanel, {class: 'arbtruck-banner-content', link: '/arbtrucks', rightContent: rightPanel});
}

export {
    arbTruckBanner
}
