import {
    patch,
    elementOpen,
    elementClose,
    text
} from 'incremental-dom';

import { PageListItem, JSXFactory, termsPages, linkClick, render } from '@appzuka/rxnano';

import { aboutPages } from 'site/pages/about/aboutPages';
import { newsPages } from 'site/pages/about/newsPages';
import { policyPages } from 'site/pages/about/policyPages';
// import { equipmentPages } from 'site/pages/equipment/equipment';
import { hirePages } from 'site/pages/hire/hire';
import { hireAerialPages } from 'site/pages/hire/hire';
import { homePages } from 'site/pages/home/home';
import { woodChipperPages } from 'site/pages/woodchippers/woodchippers';
// import { mewpsPages } from 'site/pages/mewps/mewps';
import { vehiclePages } from 'site/pages/vehicles/vehicles';
import { notFoundPage } from '@appzuka/rxnano';
import { contactPage } from '@appzuka/rxnano';
import { contactForm } from '@appzuka/rxnano-contactform';

// import { contactPage } from 'pages/contact';
import { servicePages } from 'site/pages/services/services';
import { portfolioPages } from 'site/pages/services/services';

import { SiteData, ContactAddressList, PrivacyContacts, DropMenuContent as dropmenu } from 'site/siteContent';

const CommitInfo = require('virtual/commitinfo.js');

// FIXME: Fix for narrow screens
const siteLinks = () => {
    elementOpen('div', 'hmc', null, 'class', 'hovermenucontent');
    if (typeof dropmenu === 'function') {
        dropmenu(window.location.pathname);
    } else {
        dropmenu.map((c, j) => {
            elementOpen('div', null, null, 'class', 'hovermenucolumn');
            elementOpen('ul');
                c.map(({menuText, link}, i) => {
                    let classList, finalMenuText;
                    if (menuText[0] === '*') {
                        classList = ['class', 'drop-menu-spacer'];
                        finalMenuText = menuText.slice(1);
                    } else {
                        classList = null;
                        finalMenuText = menuText;
                    }
                    elementOpen('li', null, classList);
                    elementOpen('a', `hmc-${link}-${i}`, null, 'href', link,
                    'class', window.location.pathname === link?'active-link':'other-link',
                    'onclick', (event) => {
                        linkClick(event, link);
                    });
                        text(finalMenuText);
                    elementClose('a');
                    elementClose('li');
                });
            elementClose('ul');
        elementClose('div');
        });
    }
    elementClose('div');
};

// Copy submenu into each page
const pageData = (pages) => {
    return(pages.content.map(k => {return {...k, subMenuClass: pages.subMenuClass, subMenu: pages.subMenu}}))
};

// Pass in an empty function to omit the form
const siteContactForm = (pco) => contactForm(pco, {
    testMode: !CommitInfo.production || CommitInfo.test,
    sourceSite: SiteData.siteName,
    highlight: SiteData.highlight,
    commitVersion: CommitInfo.commit
    });

const siteContactText = 
    <>
        <div class='contact-text'>
            <p>You can get in touch with me through any of the following ways:</p>
            <ul>
                <li>Complete the contact form below</li>
                <li>E-mail me directly at <a href="mailto:info@website.com">info@website.com</a></li>
            </ul>
            <p>I will respond to you as soon as I can.</p>
            <p>I look forward to working with you!</p>
        </div>
    </>

const pageList : PageListItem[] = [
    ...pageData(notFoundPage('banners/notfound', siteLinks)),
    ...pageData(contactPage([() => {render(<h2>Contact</h2>)}, ...ContactAddressList], siteContactForm)),
    ...pageData(homePages),
    ...pageData(aboutPages),
    ...pageData(newsPages),
    ...pageData(servicePages),
    ...pageData(portfolioPages), // Services portfolio

    ...pageData(woodChipperPages),
    ...pageData(hirePages),
    ...pageData(hireAerialPages),

    // ...pageData(equipmentPages),

    // ...pageData(mewpsPages),
    ...pageData(vehiclePages),


    ...pageData(policyPages),
    ...pageData(termsPages(PrivacyContacts)),
];

export {
    pageList
};
  
