
import {
  elementOpen,
  elementVoid,
  elementClose,
  text,
  skipNode
} from 'incremental-dom';

export var articleContent = () => {
  var el = elementOpen('div', 'elid', ['class', 'article']);
    elementOpen('h2');text(`Our Story`);elementClose('h2');
elementOpen('h3');text(`Overland Have Been Serving The Utility And Public Sectors With Arboriculture Services Since 2005.`);elementClose('h3');
elementOpen('div', null, ['class', 'multi-columns']);
elementOpen('p');
text('Overland believe in operating closely with our clients, we provide a personal level of service commonly best associated with smaller companies but still offer the support you would expect from a large organisation.')
elementClose('p');
elementOpen('p');
text('This outlook has helped Overland establish itself as an enterprise with a prestigious client portfolio and we are proud to supply our services and products to blue-chip companies, which includes Scottish & Southern energy, Southern electric powered Contracting, Hampshire & Berkshire County Councils.')
elementClose('p');
elementOpen('p');
text('Overland Environmental Services teamed up with Schliesing as their ethos and believes of quality, safety and innovation aligned: For more than 30 years, both Schliesing  and  Overland have been providing high-quality wood chippers for professional use in gardening and landscaping, by cities and communities for agricultural, forestry and tree-care companies. All our machines are produced in Germany and we always stay true to the claim: Made by professionals for professionals!')
elementClose('p');
elementOpen('p');
text('Arbtruck  is another Overland division that produces bespoke Arbtruck vehicles for the professional arborist, designed and tested in the forest by our own Arborists')
elementClose('p');
elementClose('div');
  elementClose('div');
  return el;
};