import { JSXFactory } from '@appzuka/rxnano';
import { DisplayListItem } from 'site/components/displayList/displayList';

interface ServicesDisplayListItem extends DisplayListItem {
    name: string
  };


export const servicesSummaryData:ServicesDisplayListItem[] = [
{
    name: 'ground',
    image: 'services/ground',
    link: '/services/ground',
    imageTitle: 'Ground Maintenance',
    content: <div class='dl-news-content'>
    <p>Overland have been providing a comprehensive tree management service to local authorities and Bluechip companies (Southern Electric) for over 15 years.</p>
    <ul>
        <li>Grass & Hedge Cutting</li>
        <li>Scrub Clearance</li>
        <li>Tree & Hedge Planting</li>
    </ul>
    </div>
},
{
    name: 'treesurgery',
    image: 'services/treesurgery',
    link: '/services/treesurgery',
    imageTitle: 'Tree Surgery',
    content: <div class='dl-news-content'>
        <p>Based in Hampshire, Overland have been called upon by homeowners and private clients to provide tree work locally and throughout the South of England. Our work includes hedge, tree and general grounds maintenance, tree felling and stump removal, new tree planting and all forms of garden fencing.</p>
    </div>
},
{
    name: 'maintenance',
    image: 'services/maintenance',
    link: '/services/maintenance',
    imageTitle: 'Equipment Maintenance',
    content: <div class='dl-news-content'>
    <p>Overland have a comprehensive workshop service for vehicles, woodchippers and aerial platforms, whether supplied by us or not.</p>
    <ul>
        <li>Workshop Service & Repairs</li>
        <li>Many Woodchipper Parts in Stock</li>
    </ul>
    </div>
},

];
