import { JSXFactory, sitePix, exportedSiteData } from '@appzuka/rxnano';
import { DisplayListItem } from 'site/components/displayList/displayList';

const AerialHire = [
    {
      name: 'tb260',
      title: 'CMC TB260',
      image: 'mewps/tb260',
      specImage: 'mewps/tb260-specs',
      dayPrice: 'POA',
      weekPrice: 'POA',
      link: '/hire/aerialplatforms/tb260',
      datasheet: ''
    },
    {
      name: 'atp145',
      title: 'CMC ATP145',
      image: 'mewps/atp145',
      specImage: 'mewps/atp145',
      dayPrice: 'POA',
      weekPrice: 'POA',
      link: '/hire/aerialplatforms/atp145',
      datasheet: ''
    },
    {
      name: 'vm135',
      title: 'VM135',
      image: 'mewps/vm135',
      specImage: 'mewps/vm135',
      dayPrice: 'POA',
      weekPrice: 'POA',
      link: '/hire/aerialplatforms/vm135',
      datasheet: ''
    },
];

interface AerialDisplayListItem extends DisplayListItem {
    name: string
  };

export const aerialSummaryData:AerialDisplayListItem[] = AerialHire.map(a => {
    return({
        image: a.image,
        link: a.link,
        imageTitle: a.title,
        title: a.title,
        name: a.name,
        content: () => {
            <h2>{a.title}</h2>
        }
    });
});

