import { PageContent, completeCallback, JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, render, patch, linkClick } from '@appzuka/rxnano';
import { first, map} from 'rxjs/operators';

import './metalBlock.scss';

const metalBlock = (leftContent, options) => {
    <render>
        <div class='metal-block'>
            <div class='bolt-strip upper'/>

            <div class={`metal-block-content ${options.class}`}>
                <div class={options.rightContent ? 'left-content' : 'single-content'}>
                    { leftContent }
                </div>
                { options.link ? <a href={options.link} class='right-content-wrapper link-panel'>
                        { options.rightContent }
                        <p class='right-content-link'>Click here for more</p>
                    </a>
                    : <div class='right-content'>
                        { options.rightContent }
                    </div>
                }
            </div>
            <div class='bolt-strip lower'/>

        </div>

    </render>

}

export {
    metalBlock
}
