import { JSXFactory } from '@appzuka/rxnano';
import { DisplayListItem } from 'site/components/displayList/displayList';

const WCHire = [
  {
    title: 'Schliesing 220MX',
    image: '220mx',
    headline: 'The Original on Tracks',
    description: 'Weight (w/o. accessory): 1600kg – Engine Manufactor: Kubota – Engine type: D – Engine power: 25.7kW – Output: 10m³/h – Drive: M',
    dayPrice: '£145',
    weekPrice: 'POA',
    link: '',
    datasheet: 'https://appzuka-web.s3.eu-west-2.amazonaws.com/overland/documents/220_MX.pdf'
  },
  {
    title: 'Schliesing 235MXRS',
    headline: 'Power on Tracks',
    description: 'Wood chipper on a high-quality track chassis - with extra wide and comfortable track width. With Stage V Kubota engine with 18.5 kW / 25.2 hp',
    image: '235-mxrs',
    dayPrice: '£145',
    weekPrice: 'POA',
    link: 'http://www.schliesing.com/?q=en/products/tracked-machines/235-300-mxrs',
    datasheet: 'http://www.schliesing.com/sites/default/files/Schliesing%20Data%20Sheet%20235%20MXRS.pdf'
  },
  {
    title: 'Schliesing 300MXRH',
    headline: 'Adjustable Height',
    description: 'Weight (w/o. accessory): 1900kg – Engine Manufactor: Kubota – Engine type: D – Travel speed : 4km/h – Engine power: 33kW – Drive: M',
    image: '300-mxrh',
    dayPrice: '£154',
    weekPrice: 'POA',
    link: '',
    datasheet: 'https://appzuka-web.s3.eu-west-2.amazonaws.com/overland/documents/235-300-mxrh.pdf'
  },
  {
    title: 'Schliesing 175MX',
    headline: 'The Light Duty',
    description: 'Wood chipper with Kubota Stage V engine 16.1 kW (21.9 hp) on single-axle chassis (80 km/h). Towing hitch with inertia brake. Jockey wheel at the front, support leg at the back. Road lights conforming to StVZO (German Road Traffic Regulations). TÜV certificate.',
    image: '175mx',
    dayPrice: '£120',
    weekPrice: 'POA',
    link: 'http://www.schliesing.com/?q=en/products/motorized-machines/175-mx',
    datasheet: 'http://www.schliesing.com/sites/default/files/Schliesing%20Data%20Sheet%20175%20MX.pdf'
  },
  {
    title: 'Schliesing 200MX',
    headline: 'The Sturdy',
    description: 'Wood chipper with Stage V Kubota engine 18.5 kW (25.2 hp) on single-axle chassis (80 km/h). Towing hitch with inertia brake. Jockey wheel at the front, support leg at the back. Road lights conforming to StVZO (German Road Traffic Regulations). TÜV certificate.',
    image: '200mx',
    dayPrice: '£125',
    weekPrice: 'POA',
    link: 'http://www.schliesing.com/?q=en/products/motorized-machines/200-mx',
    datasheet: 'http://www.schliesing.com/sites/default/files/Schliesing%20Data%20Sheet%20200%20MX.pdf'
  },
  {
    title: 'Schliesing 235EX',
    headline: 'The Universal - With a Turntable',
    description: 'Mobile Woodchipper (Compact Class), chipper unit on turntable feeding angle 270°, equipped with a Stage V water-cooled, 4-cylinder Kubota diesel engine with 18.5 kW 25.2 hp. Fully galvanized single-axle-chassis (80 km/h). Towing hitch with inertia brake. Sturdy front jockey wheel and rear support leg. Road lights as per StVZO (German Road Traffic Regulations). TÜV-expertise.',
    image: '235ex',
    dayPrice: '£130',
    weekPrice: 'POA',
    link: 'http://www.schliesing.com/?q=en/products/motorized-machines/235-300-ex',
    datasheet: 'http://www.schliesing.com/sites/default/files/Schliesing%20Data%20Sheet%20235%20EX.pdf'
  },
  {
    title: 'Schliesing 550ZX',
    headline: 'The Most Powerful',
    description: 'PTO-driven wood chipper. PTO shaft with freewheel clutch and friction coupling. Required driving power: 75-200 hp (55-147 kW)',
    image: '550zx',
    dayPrice: 'POA',
    weekPrice: 'POA',
    link: 'http://www.schliesing.com/?q=en/products/pto-driven-machines/550-zx',
    datasheet: 'http://www.schliesing.com/sites/default/files/Schliesing%20Data%20Sheet%20550%20ZX.pdf'
  },
];

export const woodchippersToHire:DisplayListItem[] = WCHire.map(w =>
  { return({
    image: `woodchippers/hire/${w.image}`,
    link: undefined,
    imageTitle: w.title,
    title: w.title,
    content: () => {
      <render>
        <div>
          <h2>{w.title}</h2>
          <h2>{w.headline}</h2>
          <p>{w.description}</p>
          { w.link && <a href={w.link} target="_blank" rel="nofollow noopener noreferrer" >Link to Schiesing Site</a> }
          <br/><a href={w.datasheet} target="_blank" rel="nofollow noopener noreferrer" >Datasheet</a>
        </div>
      </render>

    }
  })
});

export const woodchippersToBuyData:DisplayListItem[] = [
{
    image: 'woodchippers/pto/175/1',
    link: '/woodchippers/pto',
    imageTitle: 'PTO Driven Wood Chippers',
    content: <>
      <p>PTO aka tractor driven wood chipper with freewheel clutch and also available on turntable.</p>
      <p>Required power ranges from 18hp - 200hp (13 - 147kw)</p>
      <p>Wood diameter capability ranges from 13cm - 30cm</p>
      <p>Feed width available from 20cm - 34cm</p>
      <p>Machine weight, model dependant 380kg - 1,700kg</p>
      <p>Pictured is our top of the range PTO driven wood chipper, for large quantities of wood.</p>
    </>
},
{
    image: 'woodchippers/motorised/175/1',
    link: '/woodchippers/motorised',
    imageTitle: 'Motorised Wood Chippers',
    content: <>
      <p>Engine driven, road transportable wood chippers, with 22hp to 117hp</p>
      <p>Fixed on a single-axle chassis with road lights, towing hitch, jockey wheel, inertia brake and rear support leg.</p>
      <p>Wood diameter ranges from 16cm - 30cm</p>
      <p>Feed width from 24cm - 34cm</p>
      <p>Machine weight 750kg - 3,250kg</p>
    </>
},
{
    image: 'woodchippers/special/MXRS/1',
    link: '/woodchippers/special',
    imageTitle: 'Special Wood Chippers & Crawlers',
    content: <>
      <p>Wood chippers on tracks, available with two different engines for impassable terrains (Tree stumps, embankments, etc) with maximum ground clearance.</p>
      <p>Broad and sturdy track chassis for slope compensation of up to 30 degrees.</p>
      <p>Front mounted chippers for Unimog and the pruning chipper.</p>
      <p>Wood diameter capability 13cm to 30cm</p>
      <p>Feed width from 20cm to 34cm</p>
      <p>Machine weight from 440kg to 1,975kg</p>
    </>
}

];

export interface WoodChipperType {
    category: string,
    image: string,
    name: string,
    price: number | undefined,
    headline: string,
    woodDiameter: number,
    feedWidth: number,
    weight: number,
    power: number,
    description: string,
    link: string | undefined
  }
  
  export const WoodChipperList:WoodChipperType[] = [
    {
      category: 'pto',
      image: '175',
      name: '175 ZX',
      price: undefined,
      headline: 'Required power 25hp - 50hp (18-36.8kw)',
      woodDiameter: 16,
      feedWidth: 24,
      weight: 550,
      power: 36.8,
      description: `3-point rear-mount PTO wood chipper. Can be quickly mounted, the wood is chipped just as quickly. Power grip feed rollers. Powerful yet lightweight for wood diameters of upto 16cm. PTO shaft with free wheel clutch feed roller, freely adjustable chip sizes.`,
      link: 'Schliesing-wood-chipper-235-ZX-product-sheet_06.2018.pdf'
    },
    {
      category: 'pto',
      image: '235',
      name: '235 ZX',
      price: undefined,
      headline: 'Required power 25hp - 50hp (18-36.8kw)',
      woodDiameter: 19,
      feedWidth: 24,
      weight: 890,
      power: 36.8,
      description: `Schliesing best seller for the most common applications: medium-sized wood diameters, deadwood and maintenance pruning waste in larger quantities. Comes with PowerGrip feed rollers, discharge chute on ball bearing flange, nearly all exposed components are galvanised and more. Extremely robust with a feed hopper that's 50% bigger than the 175 ZX.`,
      link: 'Schliesing-wood-chipper-235-ZX-product-sheet_06.2018.pdf'
    },
    {
      category: 'pto',
      image: '400',
      name: '400 ZX',
      price: undefined,
      headline: 'Required power 50hp - 80hp (37 - 59kw)',
      woodDiameter: 21,
      feedWidth: 27,
      weight: 1500,
      power: 59,
      description: `Middle class wood chipper with chipping unit on turntable (Turning 270°). Mounted on a Fully Galvanised 24mk/h chassis. Time saving work in any position of the chipping unit, with heavy two blade cutting disc, fully galvanised ponton, discharge chute on smooth running ball bearing flange, serial sound insulation of chipping unit and discharge chute also featuring the large feed hopper distinguishing all 400 models. Big feed hopper (W x H: 1.2m x 1.06m) for handling lots of bulky chipping materials.`,
      link: 'Schliesing-wood-chipper-400-ZX-270-degree-product-sheet_06.2018.pdf'
    },
    {
      category: 'pto',
      image: '550',
      name: '550 ZX',
      price: undefined,
      headline: 'Required power is 75 - 200hp (55 - 147kw). ',
      woodDiameter: 30,
      feedWidth: 34,
      weight: 1700,
      power: 147,
      description: `The most powerful Schliesing PTO driven disc chipper as a 3-point mount. To be feed manually and/or by crane. Radio remote control option available. PowerGrip feed rollers and auxiliary transmission to increase the feeding force by up to 400%. Reinforced PTO shaft with freewheel clutch. Discharge chute on smooth-running ball bearing flange. Our PTO driven machine for large quantities of wood to be chipped.`,
      link: 'Schliesing-wood-chipper-550-ZX-product-sheet_06.2018.pdf'
    },
    {
      category: 'motorised',
      image: '175',
      name: '175 MX',
      price: undefined,
      headline: '21.6hp / 16.1kW - 3 Cyl Kubota diesel engine.',
      woodDiameter: 16,
      feedWidth: 24,
      weight: 790,
      power: 16.1,
      description: `Lightweight manoeuvrable care chipper, ideally suited for smaller towing vehicles, fully galvanised single axis chassis, as a special promotion model, serial equipped with our Power Control System and machine protection industrial freewheel clutch. Particularly simple to manoeuvre. Ideally suited for gardening and landscaping and chipping large quantities of small to medium sized material.`,
      link: 'Schliesing-wood-chipper-175-MX-product-sheet_06.2018.pdf'
    },
    {
      category: 'motorised',
      image: '200',
      name: '200 MX',
      price: undefined,
      headline: '24.8hp / 8.5kW - 3 Cyl Kubota diesel eng.',
      woodDiameter: 16,
      feedWidth: 24,
      weight: 1050,
      power: 8.5,
      description: `Compact professional wood chipper featuring the sturdy Schliesing quality for maximum durability in the processing of wood diameters of upto 16cm. Chassis and hooper flap fully galvanised. Discharge chute on smoothly running ball bearing flange with external sound insulation. Parallel-guided PowerGrip feed roller system with 2 separate hydraulic motors. Ideal for medium sizes pruning.`,
      link: 'Schliesing-wood-chipper-200-MX-product-sheet_06.2018.pdf'
    },
    {
      category: 'motorised',
      image: '235MX',
      name: '235 MX',
      price: undefined,
      headline: '235 EX 35.6hp / 26.2kW -  4 Cyl Kubota diesel engine.',
      woodDiameter: 19,
      feedWidth: 24,
      weight: 1275,
      power: 26.2,
      description: `Our universal top seller; Professional wood chipper. With water cooled 4-cylinder diesel engines, PowerGrip feed rollers for aggressive feeding. Exposed parts hot-dip galvanised (Inc the feed hopper flap), discharge chute on smoothly running ball bearing flange with external sound insulation. Wood chipper for diameters of up to 20cm for the widest range of applications: For gardening and landscaping to communities to servicing, rental and tree surgeons: The big feeding entry with PowerGrip systems processes any type of wood whether wet or dry.`,
      link: 'Schliesing-wood-chipper-235-MX-product-sheet_06.2018.pdf'
    },
    {
      category: 'motorised',
      image: '235MX',
      name: '335 MX',
      price: undefined,
      headline: '325 EX 44.9hp / 33kW - 4 Cyl Kubota turbo diesel engine.',
      woodDiameter: 19,
      feedWidth: 24,
      weight: 1300,
      power: 26.2,
      description: `Our universal top seller; Professional wood chipper. With water cooled 4-cylinder diesel engines, PowerGrip feed rollers for aggressive feeding. Exposed parts hot-dip galvanised (Inc the feed hopper flap), discharge chute on smoothly running ball bearing flange with external sound insulation. Wood chipper for diameters of up to 20cm for the widest range of applications: For gardening and landscaping to communities to servicing, rental and tree surgeons: The big feeding entry with PowerGrip systems processes any type of wood whether wet or dry.`,
      link: 'Schliesing-wood-chipper-235-MX-product-sheet_06.2018.pdf'
    },
    {
      category: 'motorised',
      image: '235EX',
      name: '235 EX',
      price: undefined,
      headline: '235 EX 24.8hp / 18.5kW - 4 Cyl Kubota diesel engine.',
      woodDiameter: 19,
      feedWidth: 30,
      weight: 1450,
      power: 18.5,
      description: `Versatile chippers for wood diameters of up to 20cm on a turntable (Tururning 270°) Thus, very advantageous when working along roads. PowerGrip feed rollers with two individual hydraulic motors for an aggressive seizing of the material; Upper feed roller with parallel guidance. Discharge chute on smoothly running ball bearing flange. Thanks to its compact design, it's an ideal machine for traffic route safety work.`,
      link: 'Schliesing-wood-chipper-235-300-EX-product-sheet_06.2018.pdf'
    },
    {
      category: 'motorised',
      image: '235EX',
      name: '325 EX',
      price: undefined,
      headline: '325 EX 44.3hp / 44.3kW - 4 Cyl Kubota turbo diesel engine.',
      woodDiameter: 19,
      feedWidth: 30,
      weight: 1450,
      power: 18.5,
      description: `Versatile chippers for wood diameters of up to 20cm on a turntable (Tururning 270°) Thus, very advantageous when working along roads. PowerGrip feed rollers with two individual hydraulic motors for an aggressive seizing of the material; Upper feed roller with parallel guidance. Discharge chute on smoothly running ball bearing flange. Thanks to its compact design, it's an ideal machine for traffic route safety work.`,
      link: 'Schliesing-wood-chipper-235-300-EX-product-sheet_06.2018.pdf'
    },
    {
      category: 'motorised',
      image: '495',
      name: '495 EX',
      price: undefined,
      headline: '495 EX 73.8hp / 55kw water cooled 4 - Cyl HATZ Common rail turbo diesel engine.',
      woodDiameter: 24,
      feedWidth: 30,
      weight: 2050,
      power: 55,
      description: `The most powerful middle class machine with a particular low emission engine with common rail technology. DPF option. Available on a single axle or tandem axle. Chipping unit on turntable with feeding angle of 270°. Hitch adjustable in height. PowerGrip feed rollers for aggressive seizing of the material. Discharge chute on a ball bearing flange. Provides the same advantages as its smaller brother 425 EX/TX but with considerably more power (75hp) with less fuel consumption. Wood diameter upto 24cm Feed width 30cm Machine weight 495 EX 2,050kg Machine weight 495 TX 2,075kg`,
      link: 'Schliesing-wood-chipper-495-EX-TX-product-sheet_06.2018.pdf'
    },
    {
      category: 'motorised',
      image: '495',
      name: '495 TX',
      price: undefined,
      headline: '495 TX 73.8hp / 55kw water cooled 4 - Cyl HATZ Common rail turbo diesel engine.',
      woodDiameter: 24,
      feedWidth: 30,
      weight: 2075,
      power: 55,
      description: `The most powerful middle class machine with a particular low emission engine with common rail technology. DPF option. Available on a single axle or tandem axle. Chipping unit on turntable with feeding angle of 270°. Hitch adjustable in height. PowerGrip feed rollers for aggressive seizing of the material. Discharge chute on a ball bearing flange. Provides the same advantages as its smaller brother 425 EX/TX but with considerably more power (75hp) with less fuel consumption.`,
      link: 'Schliesing-wood-chipper-495-EX-TX-product-sheet_06.2018.pdf'
    },
    {
      category: 'motorised',
      image: '565',
      name: '565 TX',
      price: undefined,
      headline: '565 TX 116.9hp  / 86kW 4 - Cyl  cPerkinsommon rail turbo diesel engine.',
      woodDiameter: 30,
      feedWidth: 34,
      weight: 3500,
      power: 86,
      description: `
      Chipping at its best with DOC and SCR exhaust gas purification technology. Emission level EURO IV. PowerGrip top roller with 400% increased enter force.`,
      link: 'Schliesing-wood-chipper-565-TX-product-sheet_06.2018.pdf'
    },
    {
      category: 'special',
      image: 'MXRH',
      name: '235 MXRH',
      price: undefined,
      headline: '25.2hp / 18.5kw, 4 cyl Kubota diesel engine.',
      woodDiameter: 19,
      feedWidth: 24,
      weight: 1975,
      power: 18.5,
      description: `Wood chipper, mounted on a track bed. Feed hopper with flap, pivoting di-scharge chute in accordance with EN. Adjustable chip deflector on discharge chute. Discharge chute on ball bearing flange. Noise insulation. PowerGrip chipping unit with safety circuit conforming to EN 13525. 2-blade chipping disk with fully adjustable blades. Possibility to reposition anvils (horizontally / vertically). Protection against entangling. Separate hydraulic circuit. Feed rollers with hydraulic forced feeding and separate oil motor. Hydraulic chip size adjustment. Fill level indicator for hydraulic oil tank.`,
      link: 'Schliesing-Data-Sheet-235-MXRH.pdf'
    },
    {
      category: 'special',
      image: 'MXRS',
      name: '235 MXRS',
      price: undefined,
      headline: '25.2hp / 18.5kw, 4 cyl Kubota diesel engine.',
      woodDiameter: 19,
      feedWidth: 240,
      weight: 1600,
      power: 18.5,
      description: `Machine on hydraulic track bed, based on the engine-driven model 235 MX. Serially equipped with hydraulically adjustable track beds for slope compensation (inclines of up to 30° possible); each track individually controlled (horizontally & vertically). Feed hopper with flap. Pivoting discharge chute in accordance with EN. Adjustable discharge chute deflector flap. Discharge chute on ball bearing flange. Noise insulation. PowerGrip chipping unit with safety circuit as per EN 13525 requirements. 2-blade chipping disk with fully adjustable knives. Possibility to reposition anvils (horizontally / vertically). Protection against entangling. Separate hydraulic circuit. Feed rollers with hydraulic forced feeding and separate oil motor. Chip size infinitely variable (5 – 25 mm).`,
      link: 'Schliesing-Data-Sheet-235-MXRH.pdf'
    },
    {
      category: 'special',
      image: 'U',
      name: '550 U',
      price: undefined,
      headline: 'Required power: 100-240 hp / 74-177 kw.',
      woodDiameter: 30,
      feedWidth: 34,
      weight: 1420,
      power: 177,
      description: `Front mounted wood chipper for UNIMOG trucks, PTO shaft with freewheel clutch. Support legs / frame with standard crank lever. Feed hopper with flap, pivoting discharge chute in accordance with EN. Adjustable chip deflector on discharge chute. Discharge chute on ball bearing flange. Noise insulation. PowerGrip chipping unit with safety circuit conforming to EN 13525. 3-blade chipping disk with fully adjustable blades. Possibility to reposition anvils (horizontally / vertically). Protection against entangling. Separate hydraulic circuit. Feed rollers with hydraulic forced feeding and separate oil motor. Hydraulic chip size adjustment. Fill level indicator for hydraulic oil tank.`,
      link: 'Schliesing-Data-Sheet-550-U.pdf'
    }
  
  ];
