import { PageContent, completeCallback, JSXFactory, render, sitePix, exportedSiteData } from '@appzuka/rxnano';
import { articleContent as aboutContent } from './about.md'

const aboutSubMenu = [
  { label: 'About', link: '/about' },
  { label: 'News', link: '/about/news' },
  { label: 'Testimonials', link: '/about/testimonials' },
  { label: 'Policies', link: '/about/policies' },
]

const aboutPages: PageContent = {
  content: [
    {
      pageLocation: 'about',
      image: {imageName: 'banners/aerial', title: 'About Us'},
      pageTitle: 'About',
      content: () => {
        sitePix(exportedSiteData.assetBase, 'site/oldlandy', { mediaSizes: '1100px'});
        aboutContent(); 
        completeCallback('about');
      }
    },
    {
      pageLocation: 'about/testimonials',
      image: {imageName: 'banners/aerial', title: 'About Us'},
      pageTitle: 'Testimonials',
      content: () => {
        render(<div class='about-testimonials'>
          <h2>Customer Testimonials</h2>
          { () => sitePix(exportedSiteData.assetBase, 'site/bearvalley', { mediaSizes: '1100px'})}
        </div>)
      }
    },
  ],
  subMenu: aboutSubMenu

}

export {
  aboutPages,
  aboutSubMenu
};

