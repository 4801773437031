import { PageContent, JSXFactory, render, sitePix, exportedSiteData, } from '@appzuka/rxnano';
import { PlaceholderBlock, PlaceholderPageBanner, PlaceholderMultiBlock } from 'site/placeholders/placeholders';
import { articleContent as antiSlaveryContent } from './antislavery.md'
import { articleContent as privacyContent } from './privacy.md'
import { displayList, DisplayListItem} from 'site/components/displayList/displayList';

const PolicyData = [
  // {
  //   title: 'Terms',
  //   link: 'terms',
  //   image: 'thumbnails/terms',
  //   snippet: '',
  //   body: <div class='policy terms'>
  //     <h2>Terms and Conditions</h2>
  //   </div>
  // },
  {
    title: 'Privacy',
    link: 'privacy',
    image: 'thumbnails/privacy',
    snippet: '',
    body: <div class='policy privacy'>
      { privacyContent }
    </div>
  },
  {
    title: 'Cookies', // FIXME: Show current consent
    link: 'cookies',
    image: 'thumbnails/cookies',
    snippet: '',
    body: <div class='policy cookies'>
      <h2>ABOUT THIS COOKIE POLICY</h2>
      <p>This Cookie Policy explains what cookies are and how we use them. You should read this policy to understand what cookies are, how we use them, the types of cookies we use i.e, the information we collect using cookies and how that information is used and how to control the cookie preferences. For further information on how we use, store and keep your personal data secure, see our Privacy Policy.</p>
      <p>You can at any time change or withdraw your consent from the Cookie Declaration on our website.</p>
      <p>Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy.</p>
      <p>Your consent applies to the following domains: www.overland-es.com</p>
      <p>Your current state: No consent given. Manage your consent.</p>
      <h2>WHAT ARE COOKIES?</h2>
      <p>Cookies are small text files that are used to store small pieces of information. The cookies are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make the website more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</p>
      <h2>HOW DO WE USE COOKIES ?</h2>
      <p>As most of the online services, our website uses cookies first-party and third-party cookies for a number of purposes. The first-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.</p>
      <p>The third-party cookies used on our websites are used mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.</p>
      <h2>WHAT TYPES OF COOKIES DO WE USE ?</h2>
      <p>Essential: Some cookies are essential for you to be able to experience the full functionality of our site. They allow us to maintain user sessions and prevent any security threats. They do not collect or store any personal information. For example, these cookies allow you to log-in to your account and add products to your basket and checkout securely.</p>
      <p>Statistics: These cookies store information like the number of visitors to the website, the number of unique visitors, which pages of the website have been visited, the source of the visit etc. These data help us understand and analyze how well the website performs and where it needs improvement.</p>
      <p>Marketing: Our website displays advertisements. These cookies are used to personalize the advertisements that we show to you so that they are meaningful to you. These cookies also help us keep track of the efficiency of these ad campaigns.</p>
      <p>The information stored in these cookies may also be used by the third-party ad providers to show you ads on other websites on the browser as well.</p>
      <p>Functional: These are the cookies that help certain non-essential functionalities on our website. These functionalities include embedding content like videos or sharing contents on the website on social media platforms.</p>
      <p>Preferences: These cookies help us store your settings and browsing preferences like language preferences so that you have a better and efficient experience on future visits to the website.</p>
      <h2>HOW CAN I CONTROL THE COOKIE PREFERENCES ?</h2>
      <p>Should you decide to change your preferences later through your browsing session, you can click on the “Privacy & Cookie Policy” tab on your screen. This will display the consent notice again enabling you to change your preferences or withdraw your consent entirely.</p>
      <p>In addition to this, different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. To find out more out more on how to manage and delete cookies, visit wikipedia.org, www.allaboutcookies.org.</p>
    </div>
  },
  {
    title: 'Anti-Slavery',
    link: 'antislavery',
    image: 'thumbnails/antislavery',
    snippet: '',
    body: <div class='policy antislavery'>
      <h2>Anti-Slavery</h2>
      { antiSlaveryContent }
    </div>
  },
  {
    title: 'Covid',
    link: 'covid',
    image: 'thumbnails/covid',
    snippet: '',
    body: <div class='policy covid'>
      <h2>Covid 19 Work Policy</h2>
      <p>When staff are required to travel to site to carry out works, they must ensure that where one or more members of staff are required, for Health & Safety reasons, they must travel to work in separate vehicles.</p>
      <p>Once on-site social distancing should be maintained, and if tools or equipment must be shared, then every time, said items are passed from one member of staff to another, the equipment is wiped down with Anti-bacterial cleaner.</p>
      <p>Where our staff are either on site or in the office, we will ensure that employees are able to follow Public Health England <a href='https://www.gov.uk/guidance/working-safely-during-covid-19' target="_blank" rel="nofollow noopener noreferrer">guidelines</a> including, where possible, maintaining a 2 metre distance from others, and washing their hands with soap and water frequently for at least 20 seconds (or using hand sanitiser gel if soap and water is not available).</p>
      <p>Work carried out in people’s homes, for example by tradespeople carrying out repairs and maintenance, can continue, provided that the tradesperson is well and has no symptoms. Again, it will be important to ensure that Public Health England guidelines, including maintaining a 2-metre distance from any household occupants, are followed to ensure everyone’s safety.</p>
      <p>No work should be carried out in any household which is isolating or where an individual is being shielded, unless it is to remedy a direct risk to the safety of the household, such as emergency plumbing or repairs, and where the tradesperson is willing to do so. In such cases, Public Health England can provide advice to tradespeople and households.</p>
      <p>No work should be carried out by a tradesperson who has coronavirus symptoms, however mild.</p>
      <p>As set out in the section on closing certain businesses and venues, the Government has published guidance on which organisations are covered by this requirement.</p>
      { () => sitePix(exportedSiteData.assetBase, 'site/covid19', { mediaSizes: '1100px'}) }

    </div>
  },

];

const policyList:DisplayListItem[] = PolicyData.map(p => {
  return({
    image: p.image,
    link: `/about/policies/${p.link}`,
    content: () => {
      <render>
        <h2>{p.title}</h2>
      </render>
    }
  })

});

const policyPages: PageContent = {
  content: [
    {
      pageLocation: 'about/policies',
      image: {imageName: 'banners/aerial'},
      pageTitle: 'Policies',
      content: () => {
        render(<div class='about-policies'>
          { () => displayList(policyList, {sectionTitle: 'Overland Policies'}) }
        </div>)
      }
    },
    {
      pageLocation: 'about/policies/:policy',
      image: {imageName: 'banners/aerial'},
      pageTitle: 'Policies',
      content: (_, { policy }) => {
        const thisItem = PolicyData.find(n => n.link === policy);
        if (!thisItem) {
          // TODO: Expand error message
          render(<div class="container">
            <h2>Story not found</h2>
            { () => displayList(policyList, {sectionTitle: 'Overland Policies'}) }
          </div>);
          return;
        }
        render(<div class="news-story-body">
          {/* { () => sitePix(exportedSiteData.assetBase, thisItem.image, { mediaSizes: '1100px'}) } */}
          {thisItem.body}
          { () => displayList(policyList, {sectionTitle: 'Overland Policies', filter: `/about/policies/${thisItem.link}`}) }
        </div>)
      },
      name: 'about-policy'
    },
  ],
  subMenu: [
    { label: 'Policies', link: '/about/policies' },
    // { label: 'Terms', link: '/about/policies/terms' },
    { label: 'Privacy', link: '/about/policies/privacy' },
    { label: 'Cookies', link: '/about/policies/cookies' },
    { label: 'Anti-Slavery', link: '/about/policies/antislavery' },
    { label: 'Covid', link: '/about/policies/covid' },
  ]

}

export {
  policyPages
};

