import {
    elementOpen,
    elementVoid,
    elementClose,
    text
} from 'incremental-dom';

import { ContactType, rxnanoIcons, linkClick, JSXFactory, render } from '@appzuka/rxnano';

const CommitInfo = require('virtual/commitinfo.js');

import { ISiteData } from "rxnanoModels";

export const FooterOptions = {
  policies: [
    {label: 'Cookie Policy', link: '/about/policies/cookies'},
    {label: 'Privacy Policy', link: '/about/policies/privacy'},
    {label: 'Anti-Slavery Policy', link: '/about/policies/antislavery'},
    {label: 'Covid Policy', link: '/about/policies/covid'}
  ],
  copyrightYear: '2024'
};

interface FooterContactLine {
    type: ContactType,
    icon: any,
    label: string;
    target: string
}

type FooterContactArray  = FooterContactLine[];

export const MapsAPIKey = 'AIzaSyAWGm7lJKidOYOZwusjGXLXgJApnXf5XQ8';

export const FooterContacts : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.worldIcon, label: 'www.overland-es.com', target: '/'},
    {type: ContactType.Mail, icon: rxnanoIcons.emailIcon, label: 'info@overland-es.com', target: 'info@overland-es.com'},
    {type: ContactType.Line, icon: rxnanoIcons.phoneIcon, label: '0118 981 4297', target: ''},
    {type: ContactType.External, icon: rxnanoIcons.linkedinIcon, label: 'linkedin', target: 'https://www.linkedin.com'},
    {type: ContactType.External, icon: rxnanoIcons.worldIcon, label: 'facebook', target: 'https://www.facebook.com/Overland-environmental-Services-Ltd-857559234332842/'},
    {type: ContactType.External, icon: rxnanoIcons.worldIcon, label: 'instagram', target: 'https://www.instagram.com/overland_es/'}
];

export const FooterAddress : FooterContactArray = [
    {type: ContactType.Link, icon: rxnanoIcons.locationIcon, label: 'Unit 5, Brick Kiln, Silchester Road, Tadley, Hampshire, RG26 3PX', target: '/contact'},
    {type: ContactType.Link, icon: rxnanoIcons.mailIcon, label: 'Contact Us', target: '/contact'}
];

// export const HeaderSiteLogo = (
//     <>
//         <h2 class='header-logo'>Overland</h2>
//     </>
// );

// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1534.62 223.79"><defs><style>.a{fill:#bf1635;}</style></defs>
// <path class="a" d="M135.61,23.67H1430.77c2.78.31,5.55.76,8.34.89,23.3,1.13,46.62,2.22,68.86,10.21,23.35,8.38,37.87,24.56,40.6,49.41,1.62,14.78,1.58,29.77,1.68,44.67.13,17.57.14,35.18-.79,52.71-.91,16.95-6.86,32.06-20.43,43.3-11.19,9.29-24.53,13.84-38.52,16.67-25.09,5.09-50.56,5.87-76,5.88q-623.19.12-1246.37,0c-21.79,0-43.59-.94-65.36-2.09-16.16-.85-32.06-3.73-47.19-9.82-21-8.44-34.73-22.86-38.17-45.83-.39-2.58-1.13-5.11-1.71-7.66V92.36a40.12,40.12,0,0,0,1.07-4.78c2.88-27.06,17.28-45.32,43.3-53,14.21-4.22,29.14-6.26,43.87-8.44C114.38,24.55,125,24.43,135.61,23.67ZM783.73,195.36q297.78,0,595.57-.19a269.33,269.33,0,0,0,47.06-4.41c18.34-3.3,27.1-14.27,27.56-32q.6-23.62-.1-47.28c-.47-15.2-8-24.72-22.4-29.52-17-5.66-34.72-5.89-52.35-5.89Q782.75,76,186.43,76.16a258.65,258.65,0,0,0-45.12,4.06c-20.24,3.61-28.81,15.24-28.84,35.64q0,20.16,0,40.31c0,18.75,7.63,28.9,25.68,34.15a38,38,0,0,0,7.27,1.72c14.07,1.19,28.16,3.16,42.24,3.18Q485.7,195.52,783.73,195.36Z" transform="translate(-15.69 -23.67)"/>
// <path d="M628.37,95.77v19.86H541c-7.57,0-7.58,0-7.43,7.49,0,1.28.21,2.56.36,4.37h88.83v17.34H533.94v11.82H636.09V96.13a26,26,0,0,1,3.82-.63c34.3,0,68.61-.08,102.91.35a101.21,101.21,0,0,1,24.09,3.38c11.57,3,16.12,10.29,16.15,23.11,0,12.33-3.93,17.84-15.6,21.74-.4.14-.78.36-2.46,1.13,16.52,2.25,18.19,13,17,25.55-.13,1.48-.07,3-.14,4.47a6.51,6.51,0,0,1-.47,1.34H744.91c0-3.88-.09-7.64,0-11.41.14-4.81-2.23-7.44-6.86-8.2a42.49,42.49,0,0,0-6.44-.74q-27.23-.09-54.46,0c-.95,0-1.91.2-3.49.37v20.1H496.76V131.25l-1.06-.43c-1.75,2.05-3.56,4.05-5.23,6.16-9.61,12.09-19.27,24.14-28.71,36.36-2,2.62-4,3.8-7.43,3.74-12-.21-24-.19-36,0a8.23,8.23,0,0,1-7.48-3.67c-11-14.35-22.26-28.52-33.44-42.75-.69-.88-1.49-1.68-3.18-3.56,0,6.2-.12,11.23,0,16.25.64,22.47-7.67,32.3-31.33,33.31-32.56,1.39-65.22.66-97.84.47-5.55,0-11.24-1.41-16.61-3C217.88,171,211.35,163.85,211,152.53c-.37-10.92-.32-21.89.18-32.81.52-11.3,7-18.82,17.81-21.27a141.25,141.25,0,0,1,29.58-3.73c42.3-.43,84.6-.19,126.9-.34,3.24,0,5.14,1.16,7,3.6,12.64,16.47,25.4,32.83,38.13,49.22,1.52,2,3.08,3.87,5.1,6.41,5-6,9.75-11.61,14.45-17.3,10.36-12.55,20.63-25.18,31.13-37.62,1.3-1.55,3.71-3.08,5.62-3.08q69.45-.26,138.89-.11C626.42,95.5,627.07,95.63,628.37,95.77Zm-396.28,62,.13.81c.72,0,1.6.27,2.14,0,6.92-3.91,13.18-3.54,20.76-.17,12.49,5.54,26.14,3.77,39.45,2.39a5.59,5.59,0,0,0,1.46-.31c4.91-1.9,9.68-4.39,14.75-5.62,8-1.95,12.37-8.83,19-12.59.25-.15.33-.57.54-.81,2.85-3.23,5.54-6.63,8.62-9.62,3.78-3.67,7.9-7,12.2-10.75-3.9-1.18-7.26-2.31-10.68-3.21-7.72-2.06-15.45-5.54-23.2-5.58-8.16,0-16.94-2-24.82,2.4a3.27,3.27,0,0,1-1.9.53c-10.15-1.51-17.49,5.61-25.56,9.33-9.15,4.21-15.76,13-19.34,22.57-2.27,6.06-6.21,8.39-11.46,10.15C233.48,157.43,232.78,157.56,232.09,157.73Zm441.83-22.11h5.64q25.68,0,51.36,0a43.46,43.46,0,0,0,6.46-.43c5.31-.85,7.5-3.38,7.79-8.65.37-6.76-3.07-10.24-10.92-10.29-19.45-.13-38.9,0-58.35,0a9.56,9.56,0,0,0-2,.44Z" transform="translate(-15.69 -23.67)"/>
// <path d="M789.53,177V95.79H826.1v60.9c2,.16,3.39.39,4.82.39,17.82,0,35.64.11,53.46-.11a9.14,9.14,0,0,0,6.16-2.88c15.24-18.41,30.21-37,45.45-55.47a9.17,9.17,0,0,1,6.11-3q19.74-.4,39.47,0a9.11,9.11,0,0,1,6.1,3c11.46,14,22.67,28.23,34,42.38,1,1.26,2.09,2.46,4.12,4.83V95.77c2.26-.12,3.87-.28,5.48-.29,15,0,30-.13,45,.11a13.14,13.14,0,0,1,7.53,2.71c21.37,17.44,42.57,35.08,63.83,52.65,1.22,1,2.51,1.93,4.7,3.61V95.47h6c48.63,0,97.26-.23,145.88.18a149.4,149.4,0,0,1,32.06,4c13.22,3.06,18.81,10.8,19.2,24.3.28,9.78,0,19.57-.24,29.35-.23,10-5.53,16.85-14.76,19.7a80.28,80.28,0,0,1-22.48,3.82c-39.3.37-78.61.18-117.91.17-1,0-2-.17-3.27-.29v-57h36.9v36.93c2,.14,3.49.32,4.94.33h60a33.59,33.59,0,0,0,4.49-.22c11.91-1.56,15.11-5.2,15.1-17.1,0-3,0-6,0-9,0-6.82-2.44-10.61-9.11-12.42a59.75,59.75,0,0,0-15.19-2.24c-33.81-.19-67.62-.09-101.42-.08-.82,0-1.64.16-3,.31v60.14a22.09,22.09,0,0,1-3.31.58c-16,0-32,.12-48-.08a10.94,10.94,0,0,1-6.14-2.5q-31.95-25.89-63.72-52c-1.38-1.12-2.86-2.11-5.08-3.74V177h-5.86c-14.32,0-28.65-.11-43,.08a9.25,9.25,0,0,1-8-3.63c-14.54-17.27-29.28-34.39-44-51.55-.85-1-1.78-1.91-3.13-3.34L939,143.36c2.38.22,3.74.45,5.11.45,8.66,0,17.33.27,26-.08,4.57-.19,7.81.93,10.6,4.71,3.59,4.86,7.79,9.29,12.58,14.91h-6.57c-18.82,0-37.64.12-56.46-.09-4.07,0-7.2.91-9.26,4.39-4.76,8-11.72,9.73-20.87,9.58-34.8-.57-69.61-.23-104.42-.23Z" transform="translate(-15.69 -23.67)"/></svg>

export const HeaderSiteLogo = (logoClass: string) => {
    elementOpen('div', null, null,
        'class', `header-logo-container`
    );
    elementOpen('svg', null, null,
        'class', `site-icon ${logoClass}`,
        'viewBox', '0 0 1534.62 223.79'
    );
    elementOpen('title');
        text('Site Logo');
    elementClose('title');


<rect class="cls-1" x="79.32" y="31.28" width="1394.37" height="161.07"/>

    elementVoid('rect', `background`, [
      'class', 'logo-fill',
      'x', '79.32',
      'y', '31.28',
      'width', '1394.37',
      'height', '161.07'
    ]);

    elementVoid('path', `site-logo-1`, [
      'class', 'logo-outline',
      'd', "M135.61,23.67H1430.77c2.78.31,5.55.76,8.34.89,23.3,1.13,46.62,2.22,68.86,10.21,23.35,8.38,37.87,24.56,40.6,49.41,1.62,14.78,1.58,29.77,1.68,44.67.13,17.57.14,35.18-.79,52.71-.91,16.95-6.86,32.06-20.43,43.3-11.19,9.29-24.53,13.84-38.52,16.67-25.09,5.09-50.56,5.87-76,5.88q-623.19.12-1246.37,0c-21.79,0-43.59-.94-65.36-2.09-16.16-.85-32.06-3.73-47.19-9.82-21-8.44-34.73-22.86-38.17-45.83-.39-2.58-1.13-5.11-1.71-7.66V92.36a40.12,40.12,0,0,0,1.07-4.78c2.88-27.06,17.28-45.32,43.3-53,14.21-4.22,29.14-6.26,43.87-8.44C114.38,24.55,125,24.43,135.61,23.67ZM783.73,195.36q297.78,0,595.57-.19a269.33,269.33,0,0,0,47.06-4.41c18.34-3.3,27.1-14.27,27.56-32q.6-23.62-.1-47.28c-.47-15.2-8-24.72-22.4-29.52-17-5.66-34.72-5.89-52.35-5.89Q782.75,76,186.43,76.16a258.65,258.65,0,0,0-45.12,4.06c-20.24,3.61-28.81,15.24-28.84,35.64q0,20.16,0,40.31c0,18.75,7.63,28.9,25.68,34.15a38,38,0,0,0,7.27,1.72c14.07,1.19,28.16,3.16,42.24,3.18Q485.7,195.52,783.73,195.36Z",
      'transform', 'translate(-15.69 -23.67)'
    ]);

    elementVoid('path', `site-logo-2`, [
      'class', 'logo-text',
      'd', 'M628.37,95.77v19.86H541c-7.57,0-7.58,0-7.43,7.49,0,1.28.21,2.56.36,4.37h88.83v17.34H533.94v11.82H636.09V96.13a26,26,0,0,1,3.82-.63c34.3,0,68.61-.08,102.91.35a101.21,101.21,0,0,1,24.09,3.38c11.57,3,16.12,10.29,16.15,23.11,0,12.33-3.93,17.84-15.6,21.74-.4.14-.78.36-2.46,1.13,16.52,2.25,18.19,13,17,25.55-.13,1.48-.07,3-.14,4.47a6.51,6.51,0,0,1-.47,1.34H744.91c0-3.88-.09-7.64,0-11.41.14-4.81-2.23-7.44-6.86-8.2a42.49,42.49,0,0,0-6.44-.74q-27.23-.09-54.46,0c-.95,0-1.91.2-3.49.37v20.1H496.76V131.25l-1.06-.43c-1.75,2.05-3.56,4.05-5.23,6.16-9.61,12.09-19.27,24.14-28.71,36.36-2,2.62-4,3.8-7.43,3.74-12-.21-24-.19-36,0a8.23,8.23,0,0,1-7.48-3.67c-11-14.35-22.26-28.52-33.44-42.75-.69-.88-1.49-1.68-3.18-3.56,0,6.2-.12,11.23,0,16.25.64,22.47-7.67,32.3-31.33,33.31-32.56,1.39-65.22.66-97.84.47-5.55,0-11.24-1.41-16.61-3C217.88,171,211.35,163.85,211,152.53c-.37-10.92-.32-21.89.18-32.81.52-11.3,7-18.82,17.81-21.27a141.25,141.25,0,0,1,29.58-3.73c42.3-.43,84.6-.19,126.9-.34,3.24,0,5.14,1.16,7,3.6,12.64,16.47,25.4,32.83,38.13,49.22,1.52,2,3.08,3.87,5.1,6.41,5-6,9.75-11.61,14.45-17.3,10.36-12.55,20.63-25.18,31.13-37.62,1.3-1.55,3.71-3.08,5.62-3.08q69.45-.26,138.89-.11C626.42,95.5,627.07,95.63,628.37,95.77Zm-396.28,62,.13.81c.72,0,1.6.27,2.14,0,6.92-3.91,13.18-3.54,20.76-.17,12.49,5.54,26.14,3.77,39.45,2.39a5.59,5.59,0,0,0,1.46-.31c4.91-1.9,9.68-4.39,14.75-5.62,8-1.95,12.37-8.83,19-12.59.25-.15.33-.57.54-.81,2.85-3.23,5.54-6.63,8.62-9.62,3.78-3.67,7.9-7,12.2-10.75-3.9-1.18-7.26-2.31-10.68-3.21-7.72-2.06-15.45-5.54-23.2-5.58-8.16,0-16.94-2-24.82,2.4a3.27,3.27,0,0,1-1.9.53c-10.15-1.51-17.49,5.61-25.56,9.33-9.15,4.21-15.76,13-19.34,22.57-2.27,6.06-6.21,8.39-11.46,10.15C233.48,157.43,232.78,157.56,232.09,157.73Zm441.83-22.11h5.64q25.68,0,51.36,0a43.46,43.46,0,0,0,6.46-.43c5.31-.85,7.5-3.38,7.79-8.65.37-6.76-3.07-10.24-10.92-10.29-19.45-.13-38.9,0-58.35,0a9.56,9.56,0,0,0-2,.44Z',
      'transform', 'translate(-15.69 -23.67)'
    ]);

    elementVoid('path', `site-logo-3`, [
      'class', 'logo-text',
      'd', 'M789.53,177V95.79H826.1v60.9c2,.16,3.39.39,4.82.39,17.82,0,35.64.11,53.46-.11a9.14,9.14,0,0,0,6.16-2.88c15.24-18.41,30.21-37,45.45-55.47a9.17,9.17,0,0,1,6.11-3q19.74-.4,39.47,0a9.11,9.11,0,0,1,6.1,3c11.46,14,22.67,28.23,34,42.38,1,1.26,2.09,2.46,4.12,4.83V95.77c2.26-.12,3.87-.28,5.48-.29,15,0,30-.13,45,.11a13.14,13.14,0,0,1,7.53,2.71c21.37,17.44,42.57,35.08,63.83,52.65,1.22,1,2.51,1.93,4.7,3.61V95.47h6c48.63,0,97.26-.23,145.88.18a149.4,149.4,0,0,1,32.06,4c13.22,3.06,18.81,10.8,19.2,24.3.28,9.78,0,19.57-.24,29.35-.23,10-5.53,16.85-14.76,19.7a80.28,80.28,0,0,1-22.48,3.82c-39.3.37-78.61.18-117.91.17-1,0-2-.17-3.27-.29v-57h36.9v36.93c2,.14,3.49.32,4.94.33h60a33.59,33.59,0,0,0,4.49-.22c11.91-1.56,15.11-5.2,15.1-17.1,0-3,0-6,0-9,0-6.82-2.44-10.61-9.11-12.42a59.75,59.75,0,0,0-15.19-2.24c-33.81-.19-67.62-.09-101.42-.08-.82,0-1.64.16-3,.31v60.14a22.09,22.09,0,0,1-3.31.58c-16,0-32,.12-48-.08a10.94,10.94,0,0,1-6.14-2.5q-31.95-25.89-63.72-52c-1.38-1.12-2.86-2.11-5.08-3.74V177h-5.86c-14.32,0-28.65-.11-43,.08a9.25,9.25,0,0,1-8-3.63c-14.54-17.27-29.28-34.39-44-51.55-.85-1-1.78-1.91-3.13-3.34L939,143.36c2.38.22,3.74.45,5.11.45,8.66,0,17.33.27,26-.08,4.57-.19,7.81.93,10.6,4.71,3.59,4.86,7.79,9.29,12.58,14.91h-6.57c-18.82,0-37.64.12-56.46-.09-4.07,0-7.2.91-9.26,4.39-4.76,8-11.72,9.73-20.87,9.58-34.8-.57-69.61-.23-104.42-.23Z',
      'transform', 'translate(-15.69 -23.67)'
    ]);


    elementClose('svg');
    elementOpen('h2', null, null,
        'class', 'logo-phone'
    );
    text('0118 981 4297');
    elementClose('h2');
    elementClose('div');
}

export const FooterSiteLogo = (
    <>
        <div class='footer-logo'>
        <h2>Overland</h2>
        </div>
    </>
);

interface HeadMenuItem {
    menuText: string
    link: string
    priority: number
}

type HeadMenuArray = HeadMenuItem[];
type DropMenuArray = HeadMenuArray[];

const defineMenuItem = (item:string, priority: number, link?:string) : HeadMenuItem  =>
  ({menuText: item, priority: priority, link: link===undefined?'/'+item.toLowerCase():link});

export const PageLabels = {
    'about': 'about us'
};

export const HeadMenuContent : HeadMenuArray = [
    defineMenuItem('ArbTruck', 7, '/arbtrucks'),
    defineMenuItem('Services', 7),
    defineMenuItem('Hire', 7),
    defineMenuItem('About', 7),
    defineMenuItem('Contact', 7)
];

export const TopMenuContent = HeadMenuContent;

const DropMenuContentArray: DropMenuArray = [
    [
        defineMenuItem('About Us', 1, '/about'),
        defineMenuItem('Customer Testimonials', 1, '/about/testimonials'),
        defineMenuItem('News', 1, '/about/news'),
        defineMenuItem('Home', 1, '/'),
        defineMenuItem('Contact', 1),

    ],
    [
        defineMenuItem('Sales', 1, '/arbtrucks'),
        defineMenuItem('ArbTrucks', 1, '/arbtrucks'),
        defineMenuItem('WoodChippers', 1, '/woodchippers'),
        defineMenuItem('Aerial Platforms', 1, '/hire/aerialplatforms'),
    ],
    [
        defineMenuItem('Services', 1),
        defineMenuItem('Ground Maintenance', 1, '/services/ground'),
        defineMenuItem('Tree Surgery', 1, '/services/treesurgery'),
        defineMenuItem('Equipment Maintenance', 1, '/services/maintenance'),
        defineMenuItem('Portfolio', 1, '/portfolio'),
    ],
    [
        defineMenuItem('Hire', 1, '/hire'),
        defineMenuItem('ArbTrucks', 1, '/hire/arbtrucks'),
        defineMenuItem('WoodChippers', 1, '/hire/woodchippers'),
        defineMenuItem('Aerial Platforms', 1, '/hire/aerialplatforms'),
    ],
    [
      defineMenuItem('Policies', 1, '/about/policies'),
      defineMenuItem('Privacy', 1, '/about/policies/privacy'),
      defineMenuItem('Cookie Policy', 1, '/about/policies/cookies'),
      // defineMenuItem('Terms', 1, '/policies/terms'),
      defineMenuItem('Covid 19 Policy', 1, '/about/policies/covid'),
      defineMenuItem('Slavery & Trafficking Policy', 1, '/about/policies/antislavery'),
  ],
];

type vf = (string?) => void;

export const DropMenuContent: DropMenuArray | vf = (currentLocation?: string) => {
    DropMenuContentArray.map((c, j) => {
        elementOpen('div', null, null, 'class', 'hovermenucolumn');
        elementOpen('ul');
            c.map(({menuText, link}, i) => {
                let classList, finalMenuText;
                if (menuText[0] === '*') {
                    classList = 'other-link';
                    finalMenuText = menuText.slice(1);
                } else {
                    classList = currentLocation===link?'active-link':'other-link';
                    finalMenuText = menuText;
                }
                elementOpen('li');
                elementOpen('a', `hmc-${link}-${i}`, null, 'href', link,
                'class', classList,
                'onclick', (event) => {
                    linkClick(event, link);
                });
                if (finalMenuText.length === 0) {
                    text(' ');
                } else {
                    text(finalMenuText);
                }

                elementClose('a');
                elementClose('li');
            });
        elementClose('ul');
    elementClose('div');
    });
}

type SideMenuArray = Array<Array<Array<string>>>;

const SideMenuContentArray: SideMenuArray = [
    [
        ['About Us', '/about'],
        ['Testimonials', '/about/testimonials'],
        ['News', '/about/news'],
        ['Home', '/'],
        ['Contact', '/contact']
    ],
    [
        ['Sales', '/arbtrucks'],
        ['ArbTruck', '/arbtrucks'],
        ['WoodChippers', '/woodchippers'],
        ['Aerial Platforms', '/hire/aerialplatforms']
    ],
    [
        ['Services', '/services'],
        ['Ground Maintenance', '/services/ground'],
        ['Tree Surgery', '/services/treesurgery'],
        ['Equipment Maintenance', '/services/maintenance'],
        ['Portfolio', '/portfolio']
    ],
    [
        ['Hire', '/hire'],
        ['ArbTrucks', '/hire/arbtrucks'],
        ['WoodChippers', '/hire/woodchippers'],
        ['Aerial Platforms', '/hire/aerialplatforms']
    ],
    [
      ['Policies', '/about/policies'],
      ['Privacy', '/about/policies/privacy'],
      ['Cookies', '/about/policies/cookies'],
      // ['Terms', '/policies/terms'],
      ['Covid 19', '/about/policies/covid'],
      ['Slavery', '/about/policies/antislavery']
  ],
];

// TODO: If the link us is the same the menu does not close
export const SideMenuContent: SideMenuArray | vf = (currentLocation?: string) => {
    SideMenuContentArray.map((menuColumn, j) => {
        elementOpen('div', null, null, 'class', 'sidemenucolumn');
        elementOpen('ul');
        menuColumn.map(([menuText, link], i) => {
                elementOpen('li');
                elementOpen('a', `smc-${link}-${i}`, null, 'href', link,
                'class', ((currentLocation===link) && ((j===0) || (i===0)))?'active-link':'other-link',
                'onclick', (event) => {
                    linkClick(event, link);
                });
                    text(menuText);
                elementClose('a');
                elementClose('li');
            });
        elementClose('ul');
    elementClose('div');
    });
}

interface ContactPageItem {
    icon: any,
    label: string,
    linkText: string,
    link?: string
}

type ContactPageArray = ContactPageItem[];

const defineContactPageItem = (icon: any, label:string, linkText:string, link?:string) : ContactPageItem  =>
  ({icon: icon, label: label, linkText: linkText, link: link});


const siteAddress = {
    title: () => render(<h2>Address</h2>),
    mapinfo: {
        latitude: 51.35600325864348,
        longitude: -1.139067821025153,
        zoom: 8,
        title: 'Overland'
    },
    details: [
        defineContactPageItem(rxnanoIcons.locationIcon, 'Address', 'Unit 5, Brick Kiln, Silchester Road, Tadley, Hampshire, RG26 3PX'),
        defineContactPageItem(rxnanoIcons.phoneIcon, 'Phone', '0118 981 4297'),
        defineContactPageItem(rxnanoIcons.worldIcon, 'Web', 'www.overland-es.com', '/'),
        defineContactPageItem(rxnanoIcons.emailIcon, 'eMail', 'info@overland-es.com', 'mailto:info@overland-es.com'),
        defineContactPageItem(rxnanoIcons.linkedinIcon, 'linkedIn', 'www.linkedin.com', 'https://www.linkedin.com/in/robert-nash-4066ab45/'),
        defineContactPageItem(rxnanoIcons.worldIcon, 'facebook', 'facebook.com', 'https://www.facebook.com/Overland-environmental-Services-Ltd-857559234332842/'),
        defineContactPageItem(rxnanoIcons.worldIcon, 'instagram', 'instagram.com', 'https://www.instagram.com/overland_es/'),
    ]
}

export const ContactAddressList = [
    siteAddress
]


export const SiteData:ISiteData = {
    ga: window.location.hostname==='www.overland-es.com'?null:null,
    pageTitleBase: 'Overland',
    assetBase: 'https://appzuka-web.s3.eu-west-2.amazonaws.com/overland/imagestream/',
    fullpixlist: 'fullpixlist.json',
    index: CommitInfo.index,
    siteName: 'www.overland-es.com',
    highlight: '#68b0e0',
    pageLabels: {
        '/about': 'about us'
    },
    cognitoConfig: {
        region: 'eu-west-1',
        clientID: '28toug1d6udh9ipmk1cbejd07q',
        userpool: 'eu-west-1_ktnvCHUat',
        identityPool: 'eu-west-1:72ed9f40-bf99-4692-960d-818c47b16351'
    }
}

export const PrivacyContacts = {
    company: 'Overland',
    policyDate: 'June 2021',
    contact: 'info@overland-es.com'
}

// export const assetBase = 'https://appzuka-web.s3.eu-west-2.amazonaws.com/appzuka/content/';



