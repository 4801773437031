import { PageContent, completeCallback, JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, render, patch, PageListItem } from '@appzuka/rxnano';
import { PlaceholderCarousel, PlaceholderPageBanner, PlaceholderMultiBlock } from 'site/placeholders/placeholders';
import { first, map} from 'rxjs/operators';
import { displayList } from 'site/components/displayList/displayList';

import { vehicleSummaryData, vehicleDetail} from 'site/contents/vehicles';

import './vehicles.scss';

const vehicleGallery = (filter?: string) => {
  let gel;

  <render>
    <div class='constrain-width'>
    <h2>Gallery</h2>
    <p>Click on an image below for details of {filter ? 'other ' : ''}ArbTrucks we have built for our customers</p>
    <div class='truck-gallery' ref={(r) => {gel = r; return r}}></div>
  </div>
</render>

  pixLoadedObservable
  .pipe(
  first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
  map(pl => pl.filter(p => p.file.match(new RegExp(`^gallery/arbtruck`)))), // Filter for the chosen image
  )
  .subscribe(pix => {
      const gallery:any = [];
      const trucks = new Set<string>();
      pix.map(p => {
        const m = p.file.match(/^gallery\/(arbtruck\d*)\/(.*)/) as any;
        if (m[1] !== filter) trucks.add(m[1]);
      })
      Array.from(trucks).sort((a,b) => a > b ? 1 : -1).map(t => {
        console.log(`Processing truck ${t}`);
        const imageSet = new Set<string>();
        pix.map(p => {
          const m = p.file.match(/^gallery\/(arbtruck\d*)\/(.*)/) as any;
          if (m[1] === t) imageSet.add(m[2]);
        });
        const images = Array.from(imageSet).sort((a,b) => a > b ? 1 : -1);

        gallery.push({truck: t, images: images})

      })
      patch(gel, () => {
        gallery.map(g => {
          <render>
            <a href={`/arbtrucks/gallery/${g.truck}`} class='gallery-item'>
{() => {
            sitePix(exportedSiteData.assetBase, `gallery/${g.truck}/${g.images[0]}`, { mediaSizes: '400px'});
            // g.images.map(im => {
            //   sitePix(exportedSiteData.assetBase, `gallery/arbtruck${g.truck}/${im}`, { mediaSizes: '75px'});
            // })
}}
            </a>
          </render>

        })
      });
  });




}


const galleryData = [
  {
    name: 'arbtruck1'
  },
  {
    name: 'arbtruck2'
  },
  {
    name: 'arbtruck3'
  },
  {
    name: 'arbtruck4'
  },
  {
    name: 'arbtruck5'
  },
  {
    name: 'arbtruck6'
  },
  {
    name: 'arbtruck7'
  }
];


// import './home.scss';

const vehiclePages : PageContent = {
    content: vehicleSummaryData.map((v,i) => {
      const subtitle = v.hire ? `To buy or hire` : 'To buy';
      const pc:PageListItem = {
        pageLocation: (v.link as string).substring(1),
        image: {imageName: 'banners/arbtrucklineup'},
        // image: () => insetImageBanner(`banners/forest`, `insetbanner/${v.name}`, v.title, subtitle),
        pageTitle: `Vehicles : ${v.imageTitle}`,
        content: () => {
          <render>
            <div class='constrain-width'>
              <h2>{v.title}</h2>
              { v.detail }
            </div>
          </render>
          vehicleGallery();
          displayList(vehicleSummaryData, { sectionTitle: 'Other Vehicles to Buy or Rent', filter: v.link});
        }
      };
      return(pc);
    }).concat([
      {
        pageLocation: 'arbtrucks',
        image: {imageName: 'banners/arbtrucklineup'},
        pageTitle: 'arbtrucks',
        content: () => {
          <render>
            <div>
            <h2>ArbTruck</h2>
          <p>The Arbtruck tipper conversion from Overland has been created over many years by our highly experienced Arborists and has proven itself to be a reliable, steadfast, easy to use, essential work tool.</p>
            </div>
          </render>
          displayList(vehicleSummaryData);
        }
      },
      {
        pageLocation: /^\/arbtrucks\/gallery\/(.*)/,
        image: {imageName: 'banners/arbtrucklineup'},
        pageTitle: 'arbtrucks',
        content: () => {
          const matches = window.location.pathname.match(/^\/arbtrucks\/gallery\/(.*)/);
          const thisItem = galleryData.find(n => n.name === matches![1]);
          if (thisItem) {
            let gel;
            const thisTruck = matches![1];
            render(<>
                <div class='constrain-width'>
                  <h2>Gallery for {matches![1]}</h2>
                  <div class='truck-gallery' ref={(r) => {gel = r; return r}}></div>
                  <h2>Other Arbtrucks in the Gallery</h2>
                  { () => vehicleGallery(matches![1])}
                </div>
            </>);
              pixLoadedObservable
              .pipe(
              first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
              map(pl => pl.filter(p => p.file.match(new RegExp(`^gallery/${thisTruck}`)))), // Filter for the chosen image
              )
              .subscribe(pix => {
                console.log(pix)
                  pix.sort((a,b) => a.file > b.file ? 1 : -1);
                  patch(gel, () => {
                    pix.map(im => {
                      <render>
                        <div class='gallery-item'>
                        {() => {
                          sitePix(exportedSiteData.assetBase, im.file, { mediaSizes: '400px'});
                        }}
                        </div>
                      </render>
            
                    })
                  });
              });
          } else {
            render(<>
              <p>Gallery for {matches![1]} not found</p>
              </>);
          }
        }
      },
    ]),
    subMenu: [
      { label: 'ArbTrucks', link: '/arbtrucks' },
      // { label: 'ArbTruck Ltd Edition', link: '/vehicles/arbtruck' },
      { label: 'Toyota', link: '/arbtrucks/toyota' },
      // { label: 'Transit', link: '/vehicles/transit' },
      { label: 'Iveco', link: '/arbtrucks/iveco' },
      { label: 'Fuso', link: '/arbtrucks/fuso' }
    ]
};

export {
  vehiclePages
};

