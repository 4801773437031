import { PageContent, completeCallback, JSXFactory, render, exportedSiteData, sitePix } from '@appzuka/rxnano';
import { PlaceholderBlock, PlaceholderPageBanner, PlaceholderMultiBlock } from 'site/placeholders/placeholders';
// import { EquipmentToBuy } from 'site/pages/equipment/equipment';
import { displayList } from 'site/components/displayList/displayList';
import { hireSummaryData } from 'site/contents/hire';
import { sectionHeader } from 'site/components/sectionHeader/sectionHeader';

import { woodchippersToBuyData, woodchippersToHire } from 'site/contents/woodchippers';
import { aerialSummaryData } from 'site/contents/aerial';
import { insetImageBanner } from 'site/components/insetImageBanner/insetImageBanner';

import './hire.scss';

const hirePages : PageContent = {
    content: [
        {
          pageLocation: 'hire',
          image: {imageName: 'banners/aerial', title: 'Equipment Hire'},
          pageTitle: 'Equipment Hire',
          content: <>
            { () => displayList(hireSummaryData, {sectionTitle: 'Equipment to Hire'}) }
            {/* { () => EquipmentToBuy() } */}
          </>
        },
        {
          pageLocation: 'hire/arbtrucks',
          image: {imageName: 'banners/arbtrucklineup'},
          pageTitle: 'Arbtruck Hire',
          content: <>
            { () => sectionHeader('ArbTrucks to Hire') }
            <div class='constrain-width'>
              <div class='hire-trucks-wrapper'>
                 { () => sitePix(exportedSiteData.assetBase, `gallery/arbtruck1/image1`, { mediaSizes: '300px'}) }
                 { () => sitePix(exportedSiteData.assetBase, `gallery/arbtruck2/image1`, { mediaSizes: '300px'}) }
                 { () => sitePix(exportedSiteData.assetBase, `gallery/arbtruck3/image1`, { mediaSizes: '300px'}) }
                 { () => sitePix(exportedSiteData.assetBase, `gallery/arbtruck4/image1`, { mediaSizes: '300px'}) }
              </div>
              <p>The Arbtruck tipper conversion from Overland has been created over many years by our highly experienced Arborists and has proven itself to be a reliable, steadfast, easy to use, essential work tool.</p>
            <p>We have a selection of ArbTrucks available for daily, weekly or long term hire.  Please contact us for procing and availability.</p>

            </div>



             { () => displayList(hireSummaryData, {sectionTitle: 'Other Equipment to Hire', filter: '/hire/arbtrucks'}) }
          </>
        },
        {
          pageLocation: 'hire/woodchippers',
          image: () => insetImageBanner(`banners/forest`, `insetbanner/woodchipper`, 'Woodchipper Hire', ''),
          pageTitle: 'Woodchipper Hire',
          content: <>
            { () => displayList(woodchippersToHire, {sectionTitle: 'Woodchippers to Hire'}) }
            { () => displayList(woodchippersToBuyData, {sectionTitle: 'Woodchippers to Buy'}) }
            { () => displayList(hireSummaryData, {sectionTitle: 'Other Equipment to Hire', filter: '/hire/woodchippers'}) }

          </>
        },
        {
          pageLocation: 'hire/aerialplatforms',
          image: {imageName: 'banners/aerial', title: 'Equipment Hire'},
          // image: () => insetImageBanner(`banners/forest`, `insetbanner/tb260`, 'Aerial Platform Hire', ''),
          pageTitle: 'Aerial Platform Hire',
          content: 
              <>
                { () => displayList(aerialSummaryData, {sectionTitle: 'Aerial Platforms to Hire'}) }
                { () => displayList(hireSummaryData, {sectionTitle: 'Other Equipment to Hire', filter: '/hire/aerialplatforms'}) }
              </>
        },

    ],
    subMenu: [
      { label: 'Hire', link: '/hire' },
      { label: 'ArbTrucks', link: '/hire/arbtrucks' },
      { label: 'Woodchippers', link: '/hire/woodchippers' },
      { label: 'Aerial Platforms', link: '/hire/aerialplatforms' },
    ]

}

const hireAerialPages : PageContent = {
  content: [
      {
        // pageLocation: 'aerialplatforms/:platform',
        pageLocation: /^\/hire\/aerialplatforms\/(.*)/,
        image: {imageName: 'banners/aerial', title: 'Aerial Platform Hire'},
        // image: () => {
        //   // console.log(pv)
        //   const matches = window.location.pathname.match(/hire\/aerialplatforms\/(.*)/);
        //   const thisItem = aerialSummaryData.find(n => n.name === matches![1]);
        //   if (thisItem) {
        //     render(() => PlaceholderPageBanner(`${thisItem!.title} Hire`))
        //   }
        // },
        pageTitle: 'TB260 Hire',
        content: () => {
          const matches = window.location.pathname.match(/hire\/aerialplatforms\/(.*)/);
          const thisItem = aerialSummaryData.find(n => n.name === matches![1]);
          if (thisItem) {
            render(<>
                {() => sitePix(exportedSiteData.assetBase, `mewps/${matches![1]}`, { mediaSizes: '400px'})}
                {() => sitePix(exportedSiteData.assetBase, `mewps/${matches![1]}-specs`, { mediaSizes: '400px'})}
                { () => displayList(aerialSummaryData, {sectionTitle: 'Other Aerial Platforms to Hire', filter: `/hire/aerialplatforms/${matches![1]}`}) }
                { () => displayList(hireSummaryData, {sectionTitle: 'Other Equipment to Hire', filter: '/hire/aerialplatforms'}) }
            </>);
          } else {
            render(<>
              <p>Platform {matches![1]} not found</p>
              { () => displayList(aerialSummaryData, {sectionTitle: 'Aerial Platforms to Hire'}) }
              { () => displayList(hireSummaryData, {sectionTitle: 'Other Equipment to Hire', filter: '/hire/aerialplatforms'}) }
              </>);
          }

        }
      },
  ],
  subMenu: [
    { label: 'Hire Aerial Platforms', link: '/hire/aerialplatforms/' },
    { label: 'TB260', link: '/hire/aerialplatforms/tb260' },
    { label: 'ATP145', link: '/hire/aerialplatforms/atp145' },
    { label: 'VM135', link: '/hire/aerialplatforms/vm135' },
  ]

}
// const hireVehiclePages : PageContent = {
//   content: [
//       {
//         pageLocation: /^\/hire\/vehicles\/(.*)/,
//         image: {imageName: 'banners/aerial', title: 'Aerial Platform Hire'},
//         // image: () => {
//         //   // console.log(pv)
//         //   const matches = window.location.pathname.match(/hire\/vehicles\/(.*)/);
//         //   console.log(matches)
//         //   const thisItem =  VehicleHire.find(n => n.name === matches![1]);

//         //   if (thisItem) {
//         //     render(() => PlaceholderPageBanner(`${thisItem!.title} Hire`))
//         //   }
//         // },
//         pageTitle: 'Vehicle Hire',
//         content: () => {
//           const matches = window.location.pathname.match(/hire\/vehicles\/(.*)/);
//           const thisItem = vehicleSummaryData.find(n => n.name === matches![1]);
//           if (thisItem) {
//             render(<>
//               { () => PlaceholderBlock(undefined, undefined, <div>
//                   <p>Here at overland we are able to accomodate all your business needs.</p>
//                   <p>We can offer all the vehicles and plant required to get the job finished.</p>
//                   <p>We also have many more vehicles available on request.</p>
//                 </div>) }
//                 {() => sitePix(exportedSiteData.assetBase, `vehicles/${matches![1]}`, { mediaSizes: '400px'})}
//                 { () => displayList(vehicleSummaryData, {sectionTitle: 'Vehicles to Hire'}) }
//               { () => EquipmentToHire('Vehicles') }
//             </>);
//           } else {
//             render(<>
//               <p>Platform {matches![1]} not found</p>
//               { () => displayList(vehicleSummaryData, {sectionTitle: 'Vehicles to Hire'}) }
//               { () => EquipmentToHire('Vehicles') }
//               </>);
//           }

//         }
//       },
//   ],
//   subMenu: [
//     { label: 'Hire Vehicles', link: '/hire/vehicles/' },
//     { label: 'Toyota', link: '/hire/vehicles/toyota' },
//     { label: 'Transit', link: '/hire/vehicles/transit' },
//     { label: 'Iveco', link: '/hire/vehicles/iveco' },
//     { label: 'Fuso', link: '/hire/vehicles/fuso' }
//   ]

// }

export {
    hirePages,
    hireAerialPages,
};

