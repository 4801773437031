import { PageContent, completeCallback, JSXFactory, sitePix, exportedSiteData, pixLoadedObservable, render, patch } from '@appzuka/rxnano';
import { PlaceholderCarousel, PlaceholderBlock, PlaceholderMultiBlock, PlaceholderPageBanner} from 'site/placeholders/placeholders';
import { first, map} from 'rxjs/operators';
import { displayList, DisplayListItem } from 'site/components/displayList/displayList';
import { DiagnosticCategory } from 'typescript';

import { woodchippersToBuyData, WoodChipperList, WoodChipperType, woodchippersToHire } from 'site/contents/woodchippers';
import { insetImageBanner } from 'site/components/insetImageBanner/insetImageBanner';

import './woodchippers.scss';

const filterMatches = (obj, filter) => {
  return Object.keys(filter).every(
    key => obj[key] === filter[key]
  )
}

const WoodChippersDetail = (filter:Partial<WoodChipperType>) => {

  WoodChipperList.filter(wc => filterMatches(wc, filter)).map((wc,i) => {
    let mainel, thumbel;
    PlaceholderBlock(wc.name, undefined, 
      <div>
        <div class={`wc-main-image-{i}`} ref={r => mainel = r}></div>
        <h2>{wc.headline}</h2>
        <p>{wc.description}</p>
        <p>Max wood diameter: {wc.woodDiameter}cm</p>
        <p>Feed width: {wc.feedWidth}cm</p>
        <div class={`wc-thumb-images-{i}`} ref={r => thumbel = r}></div>
        <a href={`/datasheets/${wc.category}/${wc.link}`} target="_blank" rel="nofollow noopener noreferrer" >Datasheet</a>
      </div>);
      WoodChippersSummary({category: filter.category}, `Other ${wc.category} Woodchippers to Buy`, {image: filter.image}); // NICE: Capitalise
      displayList(woodchippersToBuyData, {sectionTitle: 'Other Woodchippers to Buy', filter: `/woodchippers/${filter.category}`})
      displayList(woodchippersToHire, {sectionTitle: 'Woodchippers to Hire'});
      pixLoadedObservable
      .pipe(
        first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
        map(pl => pl.filter(p => p.file.match(new RegExp(`^woodchippers/${wc.category}/${wc.image}`)))), // Filter for the chosen image
      )
      .subscribe(pix => {
        console.log(pix);
        const pl = pix.map((p,i) => {
          const m = p.file.match(/\/(\d*)$/);
          if (m) {
            const imageIndex = parseInt(m[1]);
            console.log(m)
            return({title: imageIndex.toString(), image: p.file, index: imageIndex});
          }
          return({title: i.toString(), image: p.file, index: -1});
        })
        .filter(p => p.index > 0)
        .sort((a,b) => a.index < b.index ? -1 : 1);
        patch(mainel, () => sitePix(exportedSiteData.assetBase, pl[0].image, { mediaSizes: '400px'}));
        patch(thumbel, () => {
          pl.slice(1).map(th => {
            sitePix(exportedSiteData.assetBase, th.image, { mediaSizes: '150px'});
          })

        });
      });
  })
}

const WoodChippersSummary = (filter:Partial<WoodChipperType>, title: string | undefined, exclude?:Partial<WoodChipperType>) => {

  let mainel;
    <render>
      <div ref={r => mainel = r}></div>
    </render>

  // pixLoadedObservable
  // .pipe(
  //   first(pl => pl.length > 0), // Wait until pixlist is loaded. Cancel after first valid pixlist found
  //   map(pl => pl.filter(p => p.file.match(new RegExp(`^woodchippers/${wc.category}/${wc.image}`)))), // Filter for the chosen image
  // )
  // .subscribe(pix => {
  //   console.log(pix);
  //   const pl = pix.map((p,i) => {
  //     const m = p.file.match(/\/(\d*)$/);
  //     if (m) {
  //       const imageIndex = parseInt(m[1]);
  //       console.log(m)
  //       return({title: imageIndex.toString(), image: p.file, index: imageIndex});
  //     }
  //     return({title: i.toString(), image: p.file, index: -1});
  //   })
  //   .filter(p => p.index > 0)
  //   .sort((a,b) => a.index < b.index ? -1 : 1);
  //   patch(mainel, () => sitePix(exportedSiteData.assetBase, pl[0].image, { mediaSizes: '200px'}));
  // });
 
  let xform = WoodChipperList
  .filter(wc => filterMatches(wc, filter))
  .filter(wc => !exclude || !filterMatches(wc, exclude))
  .map((wc,i) => {return({
    image: `woodchippers/${wc.category}/${wc.image}/1`,
    imageTitle: wc.name,
    link: `/woodchippers/${wc.category}/${wc.image}`,
    content: <div class='woodchipper-summary-item-content'>
      <h2>{wc.headline}</h2>
      <p>Max wood diameter: {wc.woodDiameter}cm</p>
      <p>Feed width: {wc.feedWidth}cm</p>
    </div>
  })})
  patch(mainel, () => displayList(xform, {sectionTitle: title}));
}

const woodChipperPages : PageContent = {
    content: [
      {
        pageLocation: 'woodchippers',
        image: () => insetImageBanner(`banners/forest`, `insetbanner/woodchipper`, 'Woodchipper Hire', ''),
        pageTitle: 'Woodchippers',
        content: () => {
          <render>
            <h2>Professional Wood Chippers</h2>
            <p>Overland is the sole approved UK distributor of Schliesing wood chippers.</p>
            <p>There are three main types of wood chipper.</p>
            <ul>
              <li>PTO - Power Take Off, usually driven by a tractor.</li>
              <li>Special - These are track mounted for rough terrain.</li>
              <li>Motorised - Powered by their own diesel or petrol engine.</li>
            </ul>
            <p>Overland offer Woodchippers to Buy or Hire.  We also offer maintenance services and parts for woodchippers.</p>
            <h2>Schliesing Wood Chippers</h2>
            <p>Quality, safety and innovation: For nearly 40 years, we have been providing high-quality wood chippers for professional use in gardening and landscaping, by cities and communities and for agricultural, forestry and tree-care companies. All our machines are produced in Germany. And we always stay true to the claim: Made by professionals for professionals!</p>
            <h2>Schliesing Benefits</h2>
            <p>Schliesing machines are safe, reliable, fast and nigh indestructible.</p>
            <p>They possess an ingenious chipping design: Unique PowerGrip feed rollers, a heavy chipping disc, an enclosed cutting system, an optimal geometry of the discharge chute: Nothing wedges or clogs - It simply chips.</p>
            <p>Requires little maintenance, little wear and tear: Industrial free wheel clutch, the optionally available intelligent Power Control system etc.</p>
            <p>They have been designed for a maximum working output and service life and retain their value.</p>
            <p>It is a proven fact that Schliesing wood chippers yield the highest resale proceeds - even after many hours on the clock.</p>
          </render>
          displayList(woodchippersToBuyData, {sectionTitle: 'Woodchippers to Buy'})
          displayList(woodchippersToHire, {sectionTitle: 'Woodchippers to Hire'});
        }
    },
        {
            pageLocation: 'woodchippers/pto',
            image: () => insetImageBanner(`banners/forest`, `insetbanner/woodchipper`, 'Woodchipper Hire', ''),
            pageTitle: 'PTO Woodchippers',
            content: () => {
              <render>
                <p>Power Take Off aka PTO is a tractor driven wood chipper with a freewheel clutch.</p>
              </render>
              WoodChippersSummary({category: 'pto'}, 'Power Take Off (PTO) Woodchippers to Buy');
              displayList(woodchippersToBuyData, {sectionTitle: 'Other Woodchippers to Buy', filter: '/woodchippers/pto'})
              displayList(woodchippersToHire, {sectionTitle: 'Woodchippers to Hire'});
            }
        },
        {
            pageLocation: 'woodchippers/motorised',
            image: () => insetImageBanner(`banners/forest`, `insetbanner/woodchipper`, 'Woodchipper Hire', ''),
            pageTitle: 'Motorised Woodchippers',
            content: () => {
              <render>
                <p>Road towable, self powered by either diesel or petrol engines. Highly versatile and easily manouverable, a model for every requirement.</p>
              </render>
              WoodChippersSummary({category: 'motorised'}, 'Motorised Woodchippers to Buy');
              displayList(woodchippersToBuyData, {sectionTitle: 'Other Woodchippers to Buy', filter: '/woodchippers/motorised'})
              displayList(woodchippersToHire, {sectionTitle: 'Woodchippers to Hire'});
            }
        },
        {
            pageLocation: 'woodchippers/special',
            image: () => insetImageBanner(`banners/forest`, `insetbanner/woodchipper`, 'Woodchipper Hire', ''),
            pageTitle: 'Special Woodchippers',
            content: () => {
              <render>
                <p>Road towable, self powered by either diesel or petrol engines. Highly versatile and easily manouverable, a model for every requirement.</p>
              </render>
              WoodChippersSummary({category: 'special'}, 'Special Woodchippers to Buy');
              displayList(woodchippersToBuyData, {sectionTitle: 'Other Woodchippers to Buy', filter: '/woodchippers/special'})
              displayList(woodchippersToHire, {sectionTitle: 'Woodchippers to Hire'});
            }
        },
        {
          pageLocation: /^\/woodchippers\/(.*)\/(.*)/,
          image: {imageName: 'banners/aerial', title: undefined},
            // // console.log(pv)
            // const matches = window.location.pathname.match(/woodchippers\/(.*)\/(.*)/);
            // console.log(matches)
            // const thisItem =  WoodChipperList.find(n => (n.category === matches![1]) && (n.image === matches![2]));
  
            // if (thisItem) {
            //   render(() => PlaceholderPageBanner(`${thisItem!.image} Details`))
            // }
          // },
          pageTitle: 'Woodchippers',
          content: () => {
            const matches = window.location.pathname.match(/woodchippers\/(.*)\/(.*)/);
            const thisItem =  WoodChipperList.find(n => (n.category === matches![1]) && (n.image === matches![2]));
            if (thisItem) {
              console.log(thisItem)
              render(<>
                { 
                  () => {
                    WoodChippersDetail({category: thisItem.category, image: thisItem.image});
                    PlaceholderBlock(undefined, undefined, <div>
                      <p>Here at overland we are able to accomodate all your business needs.</p>
                      <p>We can offer all the vehicles and plant required to get the job finished.</p>
                      <p>We also have many more vehicles available on request.</p>
                    </div>);
                  }
                }
              </>);
            } else {
              render(<>
                <p>Woodchipper {matches![1]} not found</p>
                </>);
            }
  
          }
        },
        
    ],
    subMenu: [
      { label: 'WoodChippers to Buy', link: '/woodchippers' },
      { label: 'PTO', link: '/woodchippers/pto' },
      { label: 'Motorised', link: '/woodchippers/motorised' },
      { label: 'Special', link: '/woodchippers/special' }
    ]
};

export {
  woodChipperPages
};

